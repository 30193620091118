import { useLocation, useNavigate } from "react-router-dom"

function AirportDetail() {
    const location = useLocation();
    let state = location.state

    let data = state.data
    let terminal = state.data.terminalid == 'P03' || state.data.terminalid == 'G03' ? 2 : 1

    let navigate = useNavigate()
    return (
        <>
            <div className="airport-top">
                <div className="banner airport contents-wrap detail">
                    <h2>Terminal {terminal == 1 ? "One" : "Two"}</h2>
                </div>
            </div>
            <div className="airport-detail">
                <button className="btn-back" onClick={() => navigate(-1)}>
                    <span><img src="./img/ico_back.svg" alt="Go Back" /></span>Back
                </button>
                <div className="contents">
                    <h4>{data.facilitynm}</h4>
                    <div className='bottom'>
                    <p>
                        <span>Location</span>
                        {data.lcnm}
                    </p>
                    <p>
                        <span>Facility item</span>
                        {data.facilityitem}
                    </p>
                </div>
                </div>
            </div>
        </>
    )
}

export default AirportDetail