import FoodItem from "./FoodItem"

function FoodList(props) {
    document.querySelector('.food-bottom')
    let data = props.content

    return (
        <div className="contents-wrap inner">
            <div className="food-bottom">
            {data.map((item, index) => {
                return (
                    <FoodItem item={item} key={index} />
                )
            })}
            {data.length == 0 ? <div className="food-noData">There is no Data</div> : ""}
        </div>
        </div>
    )
}

export default FoodList