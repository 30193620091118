import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Detail() {
    const location = useLocation();
    const propsData = location.state;

    const ref = useRef();

    let navigate = useNavigate();
    const [height, setHeight] = useState('0px')

    const onLoad = () => {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + 30 + 'px');
      };
      useEffect(() => {
        onLoad();
      }, []);

    return (
        <>
            <div className="blog-detail contents-wrap">
                <div>
                    <button className="blog-btn" onClick={() => navigate(-1)}>
                        <img src="./img/ico_back.svg" alt="Go Back" />
                        <span>Back</span>
                    </button>
                </div>
                
                <div className='iframe-wrap'>
                    <iframe ref={ref} onLoad={onLoad} className='iframe' src={propsData} scorlling="no" height={height}></iframe>
                <button className="blog-btn" onClick={() => navigate(-1)}>
                        <img src="./img/ico_back.svg" alt="Go Back" />
                        <span>Back</span>
                </button>
                </div>
            </div>
        </>
    )
}

export default Detail;