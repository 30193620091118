import { useState, useEffect } from 'react';

import { typeCode, mapCode } from './data';
import { contentsResult } from "../../Api";

import { Link } from 'react-router-dom';

import Loading from '../../layout/Loading';

function Side({contents, setIsOpen}) {
    const { kakao } = window

    let [image, setImage] = useState()
    let [title, setTitle] = useState()
    let [overview, setOverview] = useState()
    let [address, setAddress] = useState()
    let [tel, setTel] = useState(undefined)
    let [type, setType] = useState(null)
    let [id, setId] = useState(null)
    let [locker, setLocker] = useState([])
    let [sub, setSub] = useState('station')
    let [copy, setCopy] = useState(null)

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(contents instanceof Object) {
            setTitle(contents.title)
            setOverview(contents.overview)
            setImage(contents.img)
            if(contents.locker) {
                setLocker(contents.locker)
            }
            setSub('station')
            setAddress(contents.address)

            if(contents.copy) {
                setCopy(contents.copy)
            } else {
                setCopy(null)
            }
        } else if(contents != null) {
            contentsEl()
        }
        
        async function contentsEl() {
            setLoading(true)
            let data = await contentsResult(contents)
            setLoading(false);

            setImage(data[0].firstimage)

            setTitle(data[0].title)
            setOverview(data[0].overview)
            setAddress(data[0].addr1)

            setTel(data[0].tel)
            setId(data[0].contenttypeid)
            if(data[0].contenttypeid == 39) {
                setType(typeCode[data[0].cat3])

                if(data[0].cat3 == "A05020900") {
                    setSub('cafe')
                } else {
                    setSub('restuarant')
                }
            } else if (data[0].contenttypeid == 15) {
                setSub('festival')
            } else if (data[0].contenttypeid == 28) {
                setSub('tour attraction')
            } else if (data[0].contenttypeid == 14) {
                setSub('cultural spots')
            }
        }

    }, [contents])

    function close() {
        document.querySelector('.line-detail').classList.remove('active')
    }

    return (
        <>
            <div className='line-detail right'>
                {loading ? <Loading /> : null}
                <div className='top'>
                    <img src={image ? image : "./img/noimage.png"} />
                </div> 
                
                <h4 className='title-mo'>
                        <p>{title}</p>
                        <span>{sub}</span>
                        <button onClick={close}></button>
                    </h4>
                <div className='middle'>
                    <h4 className='title'>
                        {title}
                    </h4>
                    <div className='contents'>
                        <p className='contents-bottom'>{overview}
                        </p>
                        {copy ? <span className='copy notranslate'>{copy}</span> : ""}
                    </div>
                    
                    {contents instanceof Object ? "" : <div className='contents'>
                        <p className='contents-top'>Address</p>
                        <p className='contents-bottom'>{address}</p>
                    </div>}
                    {tel == null || tel == undefined || tel == "" ? "" : <div className='contents'>
                        <p className='contents-top'>Tel</p>
                        <p className='contents-bottom'>{tel}</p>
                    </div>}
                    {id == 39 ? <div className='contents'>
                        <p className='contents-top'>Type of food</p>
                        <p className='contents-bottom'>{type}</p>
                    </div> : ""}
                    {contents instanceof Object && contents.locker ?  <div className='contents'>
                        <p className='contents-top'>Coin Lockers</p>
                        {locker.map((item, index) => {
                            return (
                                <p className='contents-bottom' key={index}>{item}</p>
                            )
                        })}
                    </div> : ""}
                    {contents instanceof Object ?  <div className='contents'>
                        <p className='contents-top'>Lost and Found</p>
                        <p className='contents-bottom'>
                            <a href='https://www.lost112.go.kr/index.do' target='_blank'>
                            https://www.lost112.go.kr/index.do
                            </a>    
                        </p>
                    </div> : ""}
                </div>
                <div className='bottom btn-area right'>
                    {contents instanceof Object && title == "Incheon Int'l Airport" ? <Link to="/airport">
                        <img src="./img/ico_back.svg" alt="Go to Airport Info" />
                        <span>Go to Airport Info</span>
                    </Link> :
                    <a href={`https://map.kakao.com/link/search/${address}`} target="_blank">
                        <img src="./img/ico_back.svg" alt="Go to Direction" />
                        <span>Get Directions</span>
                    </a>}
                </div>
            </div>
        </>
    )
}

export default Side;