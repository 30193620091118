import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Link } from 'react-router-dom';

import {courseData} from '../../Api.js'

function SwiperContents() {

    let data = courseData.items.item

    let type = ["City", "History", ,"History" ,"Histroy", "Nature", "Histroy", "Nature"]

    return (
        <>
            <div className="contents-wrap main-contents swiper-main" data-aos="fade-up">
                <h3 className="main-title">Find popular tour routes</h3>
                <Swiper
                    className="main-swiper" 
                    slidesPerView={3}
                    spaceBetween={20}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: '.swiper-button-next', // 다음 버튼 클래스명
                        prevEl: '.swiper-button-prev', // 이전 버튼 클래스명
                        }}
                        breakpoints={{
                            1000: {
                              slidesPerView: 4,
                              spaceBetween: 44
                            },
                          }}
                >
                    {data.map((item, index) => {
                        if (index > 7 || index === 2) {
                            return;
                        }
                        return (
                            <SwiperSlide key={item.contentid}>
                                <Link to="/themes/list/detail" state={item.contentid}>
                                    <div className="main-swiper-item swiper-slide">
                                        <img src={item.firstimage} alt={item.title} />
                                        <span>{type[index]}</span>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div className="swiper-button swiper-button-prev"></div>
                <div className="swiper-button swiper-button-next"></div>
            </div>
        </>
    )
}

export default SwiperContents;