import data from './data'
import ConList from './ConList'
import { Link } from 'react-router-dom';

function Another(sub) {
    let title = Object.values(sub);
    title = title[0].toLowerCase();

    if (title === "layover travelers") {
        title = "layover"
    } else if (title === "korean food") {
        title = "food"
    }
    
    return (
        <>
            <div className='blog-list sub'>
                {data[title].map((item, index) => {
                    return (
                        <div className="blog-item" key={index} >
                            <Link to="/blog/detail" state={item.contents}>
                                <ConList title={item.title} comm={item.comment} thumb={item.thumb} key={index} />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Another;