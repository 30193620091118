import { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import data from './data'

import { courseData } from '../../Api'

function List() {
    const location = useLocation();
    const propsData = location.state;
    const [nowData, setNowData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [theme, setTheme] = useState(propsData)

    let navigate = useNavigate()

    useEffect(() => {
        let allList = courseData.items.item
        
        let list = allList.filter((item) => data[theme].contents.some((el) => el == item.contentid))

        setNowData(list)
    }, [theme])

    function showModal() {
        setModalOpen(true)
    }

    function toTop() {
        window.scrollTo(0, 0);
    }

    return (
        <>
            {modalOpen && <Modal setModalOpen={setModalOpen} setTheme={setTheme} />}
            <div className={`theme-top ${theme}`}>
                <button onClick={() => navigate(-1)}><img src="./img/ico_back.svg" alt="Go Back" /> back</button>
                <div className="contents-wrap">
                    <h2>{data[theme].title}</h2>
                    <div>{data[theme].explain}</div>
                <button className='theme-all' onClick={() => showModal()}>
                    All Themes <img src="./img/ico_arrow_right_b.png" alt="Go Back" />
                </button>
                </div>
            </div>
            <div className="theme-bottom contents-wrap inner">
                <div className="left">
                    {nowData.map((item, index) => {
                        console.log(item)
                        return (
                            <Link to="/themes/list/detail" state={item.contentid} key={index}>
                                <div className='theme-item'>
                                    <div className='theme-thumb'>
                                        <img src={item.firstimage ? item.firstimage : './img/noimage.png'} alt={item.title} />
                                    </div>
                                    <div className='theme-right'>
                                        <div className='theme-right-title'>
                                            {item.title}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
            <button className='btn-top' onClick={toTop}>
                TOP
            </button>
        </>
    )
}

function Modal({setModalOpen, setTheme}) {
    function closeModal() {
        setModalOpen(false)
    }

    function set(item) {
        setTheme(item)
        closeModal()
    }

    return (
        <>
            <div className='modal theme'>
                <button className='close'><img src='../img/close.svg' alt="close the popup" onClick={closeModal} /></button>
                <p className='list-title'>Choose a theme</p>
                <div className='list'>
                <button onClick={() => set("city")}>
                            <img src="./img/theme_menu_1_illust.png" alt="City seeker's journey" />
                            <img src="./img/theme_menu_1.png" alt="City seeker's journey" />
                        </button>
                        <button onClick={() => set("food")}>
                            <img src="./img/theme_menu_2_illust.png" alt='Foodie Trip' />
                            <img src="./img/theme_menu_2.png" alt='Foodie Trip' />
                        </button>
                        <button onClick={() => set("history")}>
                            <img src="./img/theme_menu_3_illust.png" alt='Jump Back time : history tour' />
                            <img src="./img/theme_menu_3.png" alt='Jump Back time : history tour' />
                        </button>
                        <button onClick={() => set("nature")}>
                            <img src="./img/theme_menu_4_illust.png" alt='a walk into the nature' />
                            <img src="./img/theme_menu_4.png" alt='a walk into the nature' />
                        </button>
                </div>
            </div>
        </>
    )
}

export default List