import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { mapSearch } from '../Api'

import { lineArr, mapCode } from './line/data';
import Side from './line/Side'

function Linetour() {
    const location = useLocation();
    const propsData = location.state;

    let [now, setNow] = useState(propsData)
    let [nowCode, setnowCode] = useState('A01')

    function changeStation(prop) {
        setNow(prop)

        if(document.querySelector('.line-title').classList.contains('active')) {
            document.querySelector('.line-title').classList.remove('active')
            document.querySelector('.line-list').classList.remove('active')
        }
    }

    useEffect(() => {
        let nowArr = lineArr.filter((item) => item.name == now)

        setnowCode(nowArr[0].code)
    })

    function openMenu() {
        document.querySelector('.line-title').classList.toggle('active')
        if(document.querySelector('.line-title').classList.contains('active')) {
            document.querySelector('.line-list').classList.add('active')
        } else {
            document.querySelector('.line-list').classList.remove('active')
        }
    }

    let stationArr = ["Seoul", "Gongdeok", "Hongik Univ.", "Digital Media City", "Magongnaru", "Gimpo Int'l Airport", "Gyeyang", "Geomam", "Unseo", "Incheon Int'l Airport"]

    function btnChange(isPrev) {
        let index = stationArr.indexOf(now)
        let value;
        if(isPrev) {
            if(index == 0) {
                value = stationArr[9]
            } else {
                value = stationArr[index - 1]
            }
        } else {
            if(index == 9) {
                value = stationArr[0]
            } else {
                value = stationArr[index + 1]
            }
        }
        changeStation(value)
    }


    return (
        <>
            <div className='line-top'>
                <div className='contents-wrap'>
                    <h2 className='line-title' onClick={openMenu}>A’REX LINE TOUR</h2>
                    <div className='line-mo'>
                        <button className='line-button line-button-prev' onClick={() => btnChange(true)}></button>
                        <span>{nowCode}</span>
                        <p className={now == "Digital Media City" ? "small" : ""}>{now}</p>
                        <button className='line-button line-button-next' onClick={() => btnChange(false)}></button>
                    </div>
                    <ul className='line-list'>
                        {
                            lineArr.map((item, index) => {
                                return (
                                    <li key={item.name} onClick={() => {changeStation(item.name)}}>
                                        <span>{item.code}</span>
                                        <p>{item.name}</p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <Map station={now} />
        </>
    )
}

const { kakao } = window

function Map(station) {
    let nowStation = Object.values(station)
    nowStation = nowStation[0]

    const [openMenu, setOpenMenu] = useState([true, true,true,true,true,true,true])
    const [mapDiv, setMapDiv] = useState(null)
    const [myFunction, setMyFunction] = useState(null);
    const [contentsId, setContentsId] = useState(null);
    const [deleteMarker, setDeleteMarker] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [toggle, setToggle] = useState(true)

    let menu = ['All', 'station', 'cafe', 'restaurant', 'festival', 'tour attraction', 'cultural spots']

    useEffect(() => {
        const container = document.querySelector(".line-map")
        const options = {
            center: new kakao.maps.LatLng(mapCode[nowStation].Y, mapCode[nowStation].X),
            level: 3,
        }

        const map = new kakao.maps.Map(container, options)
        setMapDiv(map)
    }, [])

    useEffect(() =>{
        if(deleteMarker) {
            deleteMarker(null)

            let noMarkers = document.querySelectorAll('.line-map div div div div')
            for(let i = 0; i < noMarkers.length; i++) {
                noMarkers[i].remove()
            }
        }

        if(mapDiv) {
            if(window.innerWidth < 769) {
                mapDiv.setLevel(5)
            } else {
                mapDiv.setLevel(3)
            }

            function panTo() {
                var moveLatLon = new kakao.maps.LatLng(mapCode[nowStation].Y, mapCode[nowStation].X);
    
                mapDiv.panTo(moveLatLon);
            }
    
            panTo()
        }

        let imageSrc = './img/marker_1.png', 
            imageSize = new kakao.maps.Size(49, 60), 
            imageOption = {offset: new kakao.maps.Point(25, 60)};

        let markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
            markerPosition = new kakao.maps.LatLng(mapCode[nowStation].Y, mapCode[nowStation].X);

        let marker = new kakao.maps.Marker({
            position: markerPosition,
            image: markerImage
        })

        let markerArr = []

        marker.setMap(mapDiv)
        markerArr.push(marker)
        setContentsId(mapCode[nowStation].info)

        kakao.maps.event.addListener(marker, 'click', function() {
            setContentsId(mapCode[nowStation].info)
            document.querySelector('.line-detail').classList.add('active')
        });

        //다른 마커
        const anotherMarkerPin = async (mapDiv) => {
            const anotherMarker = await mapSearch(mapCode[nowStation].Y, mapCode[nowStation].X);

            // 데이터 정렬
            let cafeData = anotherMarker[0] ? anotherMarker[0].filter((item) => item.cat3 === "A05020900") : undefined
            let festivalData = anotherMarker[1]
            let restaurantData = anotherMarker[0] ? anotherMarker[0].filter((item) => item.cat3 !== "A05020900") : undefined
            let attractionData = anotherMarker[2]
            let culturalData = anotherMarker[3]

            //데이터 가공용 배열
            let cafePositions = []
            let festivalPositions = []
            let restaurantPositions = []
            let attractionPositions = []
            let culturalPositions = []

            let dataArr = [cafeData, restaurantData,festivalData,  attractionData, culturalData]
            let resultArr = [cafePositions, restaurantPositions, festivalPositions, attractionPositions, culturalPositions]
            
            for(let i = 0; i < dataArr.length; i++) {
                if(dataArr[i] === undefined) {
                    dataArr[i] = []
                };
                for(let j = 0; j < dataArr[i].length; j++) {
                    let contentsObject = {}
                    contentsObject.latlng = new kakao.maps.LatLng(dataArr[i][j].mapy, dataArr[i][j].mapx)
                    contentsObject.contentid = dataArr[i][j].contentid
                    contentsObject.contenttypeid = dataArr[i][j].contenttypeid

                    resultArr[i].push(contentsObject)
                }
            }
              // 마커이미지의 주소입니다. 스프라이트 이미지 입니다
            let cafeMarkers = []
            let restaurantMarkers = []
            let festivalMarkers = []
            let attractionMarkers = []
            let culturalMarkers = []

            createCafeMarkers()
            createRestaurantMarkers()
            createFestivalMarkers()
            createAttractionMarkers()
            createCulturalMarkers()

            function createMarkerImage(src, size, options) {
                var markerImage = new kakao.maps.MarkerImage(src, size, options);
                return markerImage;            
            }

            function createMarker(position, image) {
                var marker = new kakao.maps.Marker({
                    position: position.latlng,
                    image: image
                });
                
                return marker;  
            } 

            function createCafeMarkers() {
                let markerImageSrc = './img/marker_2.png';
                for (var i = 0; i < cafePositions.length; i++) {  
        
                    var imageSize = new kakao.maps.Size(49, 60),
                        imageOptions = {  
                            spriteOrigin: new kakao.maps.Point(0, 0),    
                            spriteSize: new kakao.maps.Size(49, 60)  
                        };     
                    
                    // 마커이미지와 마커를 생성합니다
                    var markerImage = createMarkerImage(markerImageSrc, imageSize, imageOptions),    
                        marker = createMarker(cafePositions[i], markerImage);  

                    let contents = cafePositions[i].contentid

                    kakao.maps.event.addListener(marker, 'click', () => {
                        setContentsId(contents)
                        document.querySelector('.line-detail').classList.add('active')
                    });
                    
                    cafeMarkers.push(marker);
                }     
            }

            function setCoffeeMarkers(mapDiv) {        
                for (var i = 0; i < cafeMarkers.length; i++) {  
                    cafeMarkers[i].setMap(mapDiv);
                }        
            }

            function createFestivalMarkers() {
                let markerImageSrc = './img/marker_3.png';
                for (var i = 0; i < festivalPositions.length; i++) {  
        
                    var imageSize = new kakao.maps.Size(49, 60),
                        imageOptions = {  
                            spriteOrigin: new kakao.maps.Point(0, 0),    
                            spriteSize: new kakao.maps.Size(49, 60)  
                        };     
                    
                    // 마커이미지와 마커를 생성합니다
                    var markerImage = createMarkerImage(markerImageSrc, imageSize, imageOptions),    
                        marker = createMarker(festivalPositions[i], markerImage);  

                        let contents = festivalPositions[i].contentid

                        kakao.maps.event.addListener(marker, 'click', () => {
                            setContentsId(contents)
                            document.querySelector('.line-detail').classList.add('active')
                        });
                    
                    festivalMarkers.push(marker);
                }     
            }

            function setFestivalMarkers(mapDiv) {        
                for (var i = 0; i < festivalMarkers.length; i++) {  
                    festivalMarkers[i].setMap(mapDiv);
                }        
            }
            
            function createRestaurantMarkers() {
                let markerImageSrc = './img/marker_4.png';
                for (var i = 0; i < restaurantPositions.length; i++) {  
        
                    var imageSize = new kakao.maps.Size(49, 60),
                        imageOptions = {  
                            spriteOrigin: new kakao.maps.Point(0, 0),    
                            spriteSize: new kakao.maps.Size(49, 60)  
                        };     
                    
                    // 마커이미지와 마커를 생성합니다
                    var markerImage = createMarkerImage(markerImageSrc, imageSize, imageOptions),    
                        marker = createMarker(restaurantPositions[i], markerImage);  

                        let contents = restaurantPositions[i].contentid

                        kakao.maps.event.addListener(marker, 'click', () => {
                            setContentsId(contents)
                            document.querySelector('.line-detail').classList.add('active')
                        });
                    
                    // 생성된 마커를 커피숍 마커 배열에 추가합니다
                    restaurantMarkers.push(marker);
                }     
            }

            function setRestaurantMarkers(mapDiv) {        
                for (var i = 0; i < restaurantMarkers.length; i++) {  
                    restaurantMarkers[i].setMap(mapDiv);
                }        
            }

            function createAttractionMarkers() {
                let markerImageSrc = './img/marker_5.png';
                for (var i = 0; i < attractionPositions.length; i++) {  
        
                    var imageSize = new kakao.maps.Size(49, 60),
                        imageOptions = {  
                            spriteOrigin: new kakao.maps.Point(0, 0),    
                            spriteSize: new kakao.maps.Size(49, 60)  
                        };     
                    
                    // 마커이미지와 마커를 생성합니다
                    var markerImage = createMarkerImage(markerImageSrc, imageSize, imageOptions),    
                        marker = createMarker(attractionPositions[i], markerImage);  

                        let contents = attractionPositions[i].contentid

                        kakao.maps.event.addListener(marker, 'click', () => {
                            setContentsId(contents)
                            document.querySelector('.line-detail').classList.add('active')
                        });
                    
                    attractionMarkers.push(marker);
                }     
            }

            function setAttractionMarkers(mapDiv) {        
                for (var i = 0; i < attractionMarkers.length; i++) {  
                    attractionMarkers[i].setMap(mapDiv);
                }        
            }

            function createCulturalMarkers() {
                let markerImageSrc = './img/marker_6.png';
                for (var i = 0; i < culturalPositions.length; i++) {  
        
                    var imageSize = new kakao.maps.Size(49, 60),
                        imageOptions = {  
                            spriteOrigin: new kakao.maps.Point(0, 0),    
                            spriteSize: new kakao.maps.Size(49, 60)  
                        };     
                    
                    // 마커이미지와 마커를 생성합니다
                    var markerImage = createMarkerImage(markerImageSrc, imageSize, imageOptions),    
                        marker = createMarker(culturalPositions[i], markerImage);  

                        let contents = culturalPositions[i].contentid
    
                        kakao.maps.event.addListener(marker, 'click', () => {
                            setContentsId(contents)
                            document.querySelector('.line-detail').classList.add('active')
                        });
                    
                        culturalMarkers.push(marker);
                }     
            }

            function setCulturalMarkers(mapDiv) {        
                for (var i = 0; i < culturalMarkers.length; i++) {  
                    culturalMarkers[i].setMap(mapDiv);
                }        
            }

            setCoffeeMarkers(mapDiv)
            setRestaurantMarkers(mapDiv)
            setFestivalMarkers(mapDiv)
            setAttractionMarkers(mapDiv)
            setCulturalMarkers(mapDiv)

            markerArr.push(setCoffeeMarkers, setRestaurantMarkers, setFestivalMarkers, setAttractionMarkers, setCulturalMarkers)

            function markerDelete(item) {
                marker.setMap(item)
                setCoffeeMarkers(item)
                setRestaurantMarkers(item)
                setFestivalMarkers(item)
                setAttractionMarkers(item)
                setCulturalMarkers(item)
            }

            function category(data) {
                for(let i = 1; i < data.length; i++) {
                    if(data[i] && i != 1) {
                        let num = i-1
                        markerArr[num](mapDiv)
                    } else if (data[i] && i == 1) {
                        markerArr[0].setMap(mapDiv)
                    } else if (!data[i] && i != 1) {
                        let num = i-1
                        markerArr[num](null)
                    } else if(i == 1) {
                        markerArr[0].setMap(null)
                    }
                }
            }
    
            setMyFunction(() => category)
            setDeleteMarker(() => markerDelete)

            markerDelete(null)
            let markers = document.querySelectorAll('.line-map div div div div')

            for(let i = 0; i < markers.length; i++) {
                markers[i].remove();
            }
            markerDelete(mapDiv)
        };

        anotherMarkerPin(mapDiv)
        setOpenMenu([true,true,true,true,true,true,true])

    }, [mapDiv, station])
    

    function categoryChange(index) {
        let change = [...openMenu]
        change[0] = false
        change[index] = !change[index]
        setOpenMenu(change)
        
        myFunction(change)
    }

    function toggleMarker(isToggle) {
        setToggle(isToggle)
        if(isToggle) {
            deleteMarker(mapDiv)
            setOpenMenu([true,true,true,true,true,true,true])
        } else {
            deleteMarker(null)
            setOpenMenu([false,false,false,false,false,false,false])
        }
    }

    return (
        <>
            <div className='line-wrap contents-wrap'>
                <ul className='line-sort'>
                    {menu.map((item, index) => {
                        return (
                            <li className={openMenu[index]?"":"none"} key={item} onClick={index == 0 ? () => toggleMarker(!toggle) : () => categoryChange(index)}>
                                <div></div>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className='line-wrap contents-wrap inner'>
                <div className='line-map left'></div>
                <Side contents={contentsId} setIsOpen={setIsOpen} className={isOpen ? "active" : ""} />
            </div>
        </>
    )
}

export default Linetour;