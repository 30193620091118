function Footer() {
    return (
        <>
            <footer>
                <div className="contents-wrap">
                    <div className="logo">
                        <img src="./img/logo.png" alt="footer logo" />
                    </div>
                    <div className="left">
                        <p className="sub">Contact</p>
                        <p className="text">
                            <span>E-mail</span>
                            Nearporttoday@gmail.com
                        </p>
                        <p className="text">
                            <span>Instagram</span>
                            @near_port
                        </p>
                        <p className="bottom">
                        © 2023. 이유란, 방소희 all rights reserved.
                        </p>
                    </div>
                    <div className="right">
                        <a href="https://knto.or.kr/index" target="_blank">
                            <img src="./img/kto_logo.png" alt="Korean Tourism Organization" />
                        </a>
                        <div className="source">
                            <p>
                                <a href="https://www.flaticon.com/kr/authors/freepik" target="_blank">
                                    All icons created by Freepik - Flaticon
                                </a>
                            </p>
                            <p>
                                <a href="https://www.freepik.com/author/stories" target="_blank">
                                    All illustration created by Storyset by Freepik
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;