let url = 'https://apis.data.go.kr/B551011/KorService1/'
let serviceKey = 'serviceKey=WhUO33en%2FEAsBZ%2FxSTESQrMB1C8m7L%2BlUSEw%2FGl6Hm%2BDp0%2FRext8v9AQYULuYIFwtfApjbooHaYtvFCCgbrTpw%3D%3D'

// let serviceKey = 'serviceKey=YVwRjLOc6SsrFjYwo43MIdATVosww9q9USFvE1%2Bg917WkVqqtCpTV7tclU2R0U%2Bz8PMqfIL45b%2BOwKa53MODKw%3D%3D'

// 추천코스
async function courseApi() {
    let response = await fetch(`${url}areaBasedList1?${serviceKey}&numOfRows=50&MobileApp=nearport&_type=json&MobileOS=ETC&contentTypeId=25&areaCode=2`);
    const jsonData = await response.json();

    return jsonData.response.body
}

let courseData = await courseApi()


// 위치 검색
async function mapSearch(y, x) {
    let mapArr = []
    let codeArr = [39, 15, 28, 14]

    let response = await fetch(`${url}locationBasedList1?${serviceKey}&numOfRows=500&MobileApp=nearport&_type=json&MobileOS=ETC&mapX=${x}&mapY=${y}&radius=1000`);
    let jsonData = await response.json();
    let item = jsonData.response.body.items.item

    for(let i = 0; i < codeArr.length; i++) {
        let data =  item.filter((el) => el.contenttypeid == codeArr[i])
        mapArr.push(data)
    }

    return mapArr
}

// 디테일 콘텐츠
async function contentsResult(id) {
    let response = await fetch(`${url}detailCommon1?${serviceKey}&MobileApp=nearport&_type=json&MobileOS=ETC&contentId=${id}&defaultYN=Y&firstImageYN=Y&addrinfoYN=Y&overviewYN=Y&catcodeYN=Y&mapinfoYN=Y`);
    let jsonData = await response.json();

    return jsonData.response.body.items.item
}

//카페 레스토랑
async function foodApi() {
    let response = await fetch(`${url}areaBasedList1?${serviceKey}&numOfRows=1000&MobileApp=nearport&_type=json&MobileOS=ETC&contentTypeId=39&areaCode=2`);
    const jsonData = await response.json();

    return jsonData.response.body
}

let foodData = await foodApi()

// 대표메뉴 가져오기
async function representMenu(id) {
    let response = await fetch(`${url}detailIntro1?${serviceKey}&MobileApp=nearport&_type=json&MobileOS=ETC&contentId=${id}&contentTypeId=39`);
    let jsonData = await response.json();

    return jsonData.response.body.items.item
}

// 추천코스 하위 콘텐츠 가져오기
async function courseContents(id) {
    let response = await fetch(`${url}detailInfo1?${serviceKey}&MobileApp=nearport&_type=json&MobileOS=ETC&contentId=${id}&contentTypeId=25`);
    let jsonData = await response.json();

    return jsonData.response.body.items.item
}
async function courseInfo(id) {
    let response = await fetch(`${url}detailIntro1?${serviceKey}&MobileApp=nearport&_type=json&MobileOS=ETC&contentId=${id}&contentTypeId=25`);
    let jsonData = await response.json();

    return jsonData.response.body.items.item
}
async function courseDetail(id) {
    let response = await fetch(`${url}detailCommon1?${serviceKey}&MobileApp=nearport&_type=json&MobileOS=ETC&contentId=${id}&contentTypeId=25&defaultYN=Y&firstImageYN=Y&addrinfoYN=Y&overviewYN=Y&mapinfoYN=Y`);
    let jsonData = await response.json();

    return jsonData.response.body.items.item
}

async function airportInfoApi() {
    let response = await fetch(`http://apis.data.go.kr/B551177/FacilitiesInformation/getFacilitesInfo?${serviceKey}&lang=E&type=json&numOfRows=500`, {
        headers: {
            "Accept": "text/html",
          },
    })
    let jsonData = await response.json();

    return jsonData.response.body.items
}

async function airportInfoApi2() {
    let response = await fetch(`http://apis.data.go.kr/B551177/FacilitiesInformation/getFacilitesInfo?${serviceKey}&lang=E&type=json&numOfRows=500&lcduty=Y`)
    let jsonData = await response.json();

    return jsonData.response.body.items
}

async function weather1() {
    let today = new Date()
    let date = today.getDate()
    let yesterday = new Date(today.setDate(date-1))

    let year = yesterday.getFullYear()
    let month = yesterday.getMonth() + 1
    let day = yesterday.getDate()

    let now = `${year}${month<10 ? "0"+month : month}${day<10 ? "0"+day : day}`

    let response = await fetch(`http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getVilageFcst?${serviceKey}&numOfRows=500&pageNo=1&dataType=JSON&ftype=SHRT&base_date=${now}&base_time=2300&nx=51&ny=125`)
    let jsonData = await response.json();

    let data = jsonData.response.body.items.item
    let max = data.filter((item) => item.category == 'TMX')
    let min = data.filter((item) => item.category == 'TMN')

    data = [max[0], min[0]]

    return data
}

let weatherResult1 = await weather1()

async function weather2() {
    let today = new Date()
    let year = today.getFullYear()
    let month = today.getMonth() + 1
    let date = today.getDate()

    let hour = today.getHours()

    if(hour < 2) {
        hour = 24 + (hour - 2)
        date = date - 1
    } else {
        hour = hour - 2
    }

    let day = `${year}${month<10 ? "0"+month : month}${date<10 ? "0"+date : date}`

    let time = `${hour<10 ? "0"+hour : hour}00`

    let response = await fetch(`http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getUltraSrtNcst?${serviceKey}&numOfRows=10&pageNo=1&dataType=JSON&base_date=${day}&base_time=${time}&nx=51&ny=125`)
    let jsonData = await response.json();

    let data = jsonData.response.body.items.item

    data = data.filter((item) => item.category == 'T1H')

    return data[0]
}
let weatherResult2 = await weather2()

export { courseData, mapSearch, contentsResult, foodData, representMenu, courseContents, courseInfo, courseDetail, airportInfoApi, airportInfoApi2, weatherResult1, weatherResult2 }