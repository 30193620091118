import React from 'react';

function Loading() {
    return (
        <>
            <div className='loading-wrap'>
                <img src='../img/download.gif' />
                <div className='loading-text'>
                    Loading
                </div>
            </div>
        </>
    )
}

export default Loading