import { Link } from 'react-router-dom';

function Header() {
    function openMenu() {
        if(window.innerWidth < 769) {
            document.querySelector('.hamburger').classList.toggle('active')
            if(document.querySelector('.hamburger').classList.contains('active')) {
                document.querySelector('nav').classList.add('active')
            } else {
                document.querySelector('nav').classList.remove('active')
            }
        }
    }

    return (
        <>
            <header>
                <div className="header-logo">
                    <Link to="/">
                        <img src="./img/logo.png" alt="Go to Near, port main page" />
                    </Link>
                </div>
                <nav className='notranslate'>
                    <div onClick={openMenu}>
                        <Link to="/airport">AIRPORT INFO</Link>
                    </div>
                    <div onClick={openMenu}>
                        <Link to="/arex" state={"Seoul"}>A'REX LINE TOUR</Link>
                    </div>
                    <div onClick={openMenu}>
                        <Link to="/themes">THEMED TOUR</Link>
                    </div>
                    <div onClick={openMenu}>
                        <Link to="/food">CAFÉ & RESTAURANTS</Link>
                    </div>
                    <div onClick={openMenu}> 
                        <Link to="/blog" onClick={() => {
                            sessionStorage.setItem("blog", "0")
                        }}>BLOG</Link>
                    </div>
                </nav>
                <button className='hamburger' onClick={openMenu}></button>
            </header>
        </>
    );
}

export default Header;