import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { contentsResult, representMenu } from "../../Api";

function FoodDetail() {
    const location = useLocation();
    const propsData = location.state;
    const { kakao } = window

    let typeCode = {
        "A05020100" : "Korean food",
        "A05020200" : "Western food",
        "A05020300" : "Japanese food",
        "A05020400" : "Chinese food",
        "A05020700" : "intercultural food",
        "A05020900" : "Cafe/Teahouse"
    }

    const [title, setTitle] = useState(null)
    const [src, setSrc] = useState(null)
    const [overview, setOverview] = useState(null)
    const [address, setAddress] = useState(null)
    const [tel, setTel] = useState(null)
    const [type, setType] = useState(null)
    const [menu, setMenu] = useState(null)

    useEffect(() => {
        async function apiResult() {
            let data = await contentsResult(propsData)

            setTitle(data[0].title)
            setSrc(data[0].firstimage)
            setOverview(data[0].overview)
            setAddress(data[0].addr1)
            setTel(data[0].tel)
            setType(typeCode[data[0].cat3])

        const container = document.querySelector(".food-map")
        const options = {
            center: new kakao.maps.LatLng(data[0].mapy, data[0].mapx),
            level: 3,
        }

        const map = new kakao.maps.Map(container, options)
        let imageSrc = data[0].cat3 !== 'A05020900' ? './img/marker_4.png' : './img/marker_2.png', 
            imageSize = new kakao.maps.Size(49, 60), 
            imageOption = {offset: new kakao.maps.Point(25, 60)};

        let markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
            markerPosition = new kakao.maps.LatLng(data[0].mapy, data[0].mapx);

        let marker = new kakao.maps.Marker({
            position: markerPosition,
            image: markerImage
        })

        marker.setMap(map)
        }

        async function menuResult() {
            let data = await representMenu(propsData) 

            setMenu(data[0].firstmenu)
        }

        apiResult()
        menuResult()
    }, [])

    return (
        <>
            <div className='food-detail contents-wrap inner'>
                <div className='top'>
                    <h2>{title}</h2>
                    <p className='notranslate'>{title}</p>
                </div>
                <div className='middle'>
                    <div className='left'>
                        <img src={src ? src : "./img/noimage.png"} alt={title} />
                    </div>
                    <div className='right'>
                        <p className='overview'>
                            {overview}
                        </p>
                        <ul>
                            <li>
                                <p>Address</p>
                                <span>{address}</span>
                            </li>
                            <li>
                                <p>Tel</p>
                                <span>{tel}</span>
                            </li>
                            <li>
                                <p>Type of food</p>
                                <span>{type}</span>
                            </li>
                            <li>
                                <p>Representative</p>
                                <span>{menu}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='food-map'></div>
                    <div className='btn-area right'>
                        <a href={`https://map.kakao.com/link/search/${address}`} target="_blank" className='btn btn-green-border'>
                            Get Directions
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FoodDetail