let data = {
    city : {
        title: "City seeker’s journey",
        explain: "Cars passing by, bright signs, and being surrounded by buildings, cities sometimes give you a hint of being alive. In this theme, you can find routes that you can enjoy inside the blinding lights.",
        contents: ["2961651", "1945733", "2987551", "2936862", "2987555", "1977260", "1909316", "1976468", "1960287", "1976469", "2700394"],
        typeName: ['type1', 'type2', 'type3'],
        type1: ["type1_1","type1_2","type1_3"],
        type2: ["type2_1","type2_2","type2_3","type2_4"],
        type3: ["type3_1","type3_2","type3_3","type3_4","type3_5"],
        conType1: [["2961651", "1945733", "2987551"],["1909316", "1976468"], ["1960287", "1976469", "2700394"]],
        conType2: [["2961651"],["1945733", "2987551"], ["2936862"], ["1909316"]],
        conType3: [["1976468", "2700394"],["2961651"], ["1945733"], ["1976469"], ["1960287"]]
    },
    food : {
        title: "Foodie Trip",
        explain: "Are you a foodie who cares more about what you will eat in a trip? This theme introduces all the great food you can try here in Incheon. Trust me, Koreans make real good food that won’t disappoint you.",
        contents: ["2030910", "2372032"]
    },
    history : {
        title: "Jump Back Time : History Tour",
        explain: "Korea’s history starts from way back. With a long time passing by, it also has a lot of stories we can tell. This theme is all about history, and the people inside it.",
        contents: ["1886027", "1909304", "1977229", "1886018", "2360808", "1909256", "1886015", "1968944", "2537161", "2722497"]
    },
    nature : {
        title: "A WALK INTO THE NATURE",
        explain: "More than 70% of Korea are mountains. We have a lot of nature within us, including forests and parks. In this theme, you can take a walk by the greens touching your heart.",
        contents: ["2987537", "1886033", "2987545", "1885994", "1909290", "1885694", "1909312",  "2987504", "2659837", "2357525", "1909257"]
    },
    map : {
        "2961651" : [["126.484277", "37.7464927"],["126.479776", "37.7475991"],["126.708879,", "37.6126411"]],
        "1886027" : [["126.53001269892653", "37.66515392729978"],["126.525495", "37.6475063"],["126.51709845668549", "37.73321941004716"],["126.532471", "37.6336954"]],
        "1909304" : [["126.24427670427963", "37.643580071956045"],["126.477481", "37.6546368"],["126.468021", "37.6527204"]],
        "1977229" : [["126.282981", "37.7844575"],["126.374250", "37.7011813"],["126.354245", "37.7718338"],["126.296942", "37.7750639"]],
        "1886018" : [["126.4857718773289", "37.74790545786342"],["126.437432", "37.7729820"],["126.487073", "37.7473608"],["126.431659", "37.8270379"],["126.24427670427963", "37.643580071956045"],["126.51191880912175", "37.77094376611361"]],
        "1945733" : [["126.415083", "37.4165827"],["126.41727207739586", "37.392121729752894"],["126.415083", "37.4165827"],["126.41846776268864", "37.386811652903944"],["126.41332405425966", "37.38322725180195"],["126.41332405425966", "37.38322725180195"],["126.41727207739586", "37.392121729752894"],["126.41846776268864", "37.386811652903944"]],
        "2360808" : [["126.616690", "37.4754623"],["126.634623", "37.3960596"],["126.605679", "37.4669385"],["126.513217", "37.4715842"],["126.599189", "37.4735389"],["126.3727285679603", "37.44629195762292"]],
        "2987537" : [["126.427856", "37.6179888"],["126.484952", "37.6329113"],["126.548643", "37.7391714"],["126.708879", "37.6126411"]],
        "2987551" : [["126.282981", "37.7844575"],["126.479776", "37.7475991"],["126.594025", "37.7533199"],["126.740487", "37.8915622"],["126.705445", "37.7304345"]],
        "1886033" : [["126.435373", "37.7035807"],["126.484952", "37.6329113"],["126.324007", "37.6947588"],["126.427856", "37.6179888"],["126.453135", "37.6120544"]],
        "2987545" : [["125.97950805112623", "37.18851934294055"],["126.497762", "37.6165239"],["126.479776", "37.7475991"],["126.548643", "37.7391714"],["126.574406", "37.7375828"]],
        "1885994" : [["126.40985353552075", "37.38483180367556"],["126.41421609520653", "37.41840705794589"],["126.41354273603038", "37.40914587432201"],["126.378788", "37.4397510"],["126.3727285679603", "37.44629195762292"],["126.40294826053244", "37.403407489699816"]],
        "1909290" : [["126.321466", "37.6886718"],["126.33342117052992", "37.65128014444002"],["126.32819056092923", "37.69508461301108"],["126.333388", "37.6525664"]],
        "2936862" : [["126.491500", "37.6395709"],["126.486259", "37.7485646"],["126.484952", "37.6329113"],["126.281090", "37.7804278"],["126.431659", "37.8270379"]],
        "2987555" : [["125.71371469267898", "37.65550426431952"],["126.282981", "37.7844575"],["126.484277", "37.7464927"],["126.594025", "37.7533199"]],
        "1885694" : [["124.699792", "37.9281583"],["124.713619", "37.9798882"],["124.67828568341785", "37.953702398341555"]],
        "1909312" : [["126.359614", "37.7192297"],["126.390068", "37.7052556"],["126.374250", "37.7011813"],["126.353547", "37.7243320"],["126.431919", "37.7370616"]],
        "1909256" : [["126.487073", "37.7473608"],["126.48575479580167", "37.747914392927235"],["126.483282", "37.7525427"],["126.47850625299711", "37.753713996911785"],["126.51790745403525", "37.73267088191585"],["126.486259", "37.7485646"],["126.484142", "37.7560602"],["126.51191592728405", "37.7709505113503"]],
        "2722497" : [["126.516981", "37.7336218"],["126.431659", "37.8270379"],["126.281090", "37.7804278"],["126.621230", "37.4742246"]],
        "1977260" : [["126.698614", "37.5940910"],["126.674426", "37.5453462"],["126.656330", "37.5580347"],["126.666569", "37.5463062"],["126.708879", "37.6126411"]],
        "1909316" : [["126.434618", "37.7743470"],["126.437432", "37.7729820"],["126.453695", "37.7962942"],["126.424361", "37.7810903"]],
        "2372032" : [["126.645999", "37.3881384"],["126.643537", "37.3859505"],["126.634623", "37.3960596"],["126.644013", "37.3925744"],["126.650134", "37.3949264"]],
        "1976468" : [["126.618376", "37.4774229"],["126.606537", "37.4540931"],["126.513217", "37.4715842"],["126.598830", "37.4772138"],["126.618605", "37.4745855"],["126.59853709022951", "37.454271028855786"]],
        "1960287" : [["126.618563", "37.4739997"],["126.621562", "37.4732706"],["126.486259", "37.7485646"],["126.621846", "37.4752537"],["126.620840", "37.4730881"]],
        "1976469" : [["126.628695", "37.4757170"],["126.62877002073004", "37.47597599900304"],["126.62038837732987", "37.47796987056036"],["126.621230", "37.4742246"]],
        "1886015" : [["126.62141758070699", "37.472848708347705"],["126.618376", "37.4774229"],["126.621846", "37.4752537"],["126.604464", "37.4764580"]],
        "2030910" : [["126.62038837732987", "37.47796987056036"],["126.740567", "37.3986744"],["126.620551", "37.4787454"],["126.618376", "37.4774229"],["126.618795", "37.4751267"]],
        "1968944" : [["126.690913", "37.4360004"],["126.683269", "37.4373226"],["126.662840", "37.4598811"]],
        "2987504" : [["127.143472", "38.0774103"],["126.740548", "37.8902560"],["126.744148", "37.8913271"],["126.987083", "38.1178651"],["126.406217", "37.5290273"],["126.708879", "37.6126411"]],
        "2537161" : [["126.514875", "37.7347123"],["126.508929", "37.7161279"],["126.427856", "37.6179888"]],
        "2659837" : [["126.479776", "37.7475991"],["126.279288", "37.7813177"],["126.240772", "37.8168335"],["126.281090", "37.7804278"]],
        "2357525" : [["125.97950805112623", "37.18851934294055"],["126.59853709022951", "37.454271028855786"],["126.12557030196062", "37.23190035258442"],["126.11381894712201", "37.221410736466396"]],
        "1909257" : [["126.513204", "37.7075595"],["126.532471", "37.6336954"],["126.525495", "37.6475063"],["126.530221", "37.6653375"],["126.516271", "37.6771827"]],
        "2700394" : [["126.598830", "37.4772138"],["126.62038837732987", "37.47796987056036"],["126.621230", "37.4742246"],["126.639565", "37.3924167"],["126.740567", "37.3986744"]]
    }
}

export default data