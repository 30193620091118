import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

import { airportInfoApi, airportInfoApi2, weatherResult1, weatherResult2 } from '../Api'

function Airport() {
    const isMobile = useMediaQuery({ maxWidth: 768 })

    const [t1normal, setT1normal] = useState([])
    const [t2normal, setT2normal] = useState([])
    const [t1free, setT1free] = useState([])
    const [t2free, setT2free] = useState([])

    useEffect(() => {
        async function result() {
            let airportInfo = await airportInfoApi()
            let airportInfo2 = await airportInfoApi2()

            let t1n = airportInfo.filter((item) => item.terminalid == 'P01' || item.terminalid == 'G02')
            let t2n = airportInfo.filter((item) => item.terminalid == 'P03' || item.terminalid == 'G03')
            let t1f = airportInfo2.filter((item) => item.terminalid == 'P01' || item.terminalid == 'G02')
            let t2f = airportInfo2.filter((item) => item.terminalid == 'P03' || item.terminalid == 'G03')

            setT1normal(t1n)
            setT2normal(t2n)
            setT1free(t1f)
            setT2free(t2f)
        }

        result()
    }, [])

    return (
        <>
            <div className="airport-top none">
                <div className="banner airport contents-wrap">
                    <h2>Incheon Airport</h2>
                    <p className='text-center'>
                    Welcome to South Korea! From emergency calls  to restaurants and duty free shops, we got all the information you need for one of the largest, and best airports in the world.
                    </p>
                    <div className="background">
                        <img src="../img/airport_1.png" />
                        <img src="../img/airport_2.png" />
                    </div>
                </div>
            </div>
            {!isMobile && <PcComponent t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} />}
            {isMobile && <MoComponent />}
        </>
    )
}

function MoComponent() {
    function openToggle(index) {
        let btn = document.querySelector('.airport-depth1.open')
        let el = document.querySelector('.airport-menu.open')

        btn.classList.toggle('active')

        if(btn.classList.contains('active')) {
            el.classList.add('active')
        } else {
            el.classList.remove('active')
        }
    }

    return (
        <>
            <div className='airport-bottom contents-wrap'>
                <div className='airport-item'>
                    <button className='none'>Airport Info</button>
                    <div className='airport-menu active'>
                        <DetailAirport />
                    </div>
                </div>

                <div className='airport-item top'>
                    <button className="airport-depth1 open" onClick={openToggle}>Category</button>
                    <ul className='airport-menu airport-category open'>
                        <li>
                            <button className="airport-depth1">Terminal 1</button>
                            <div>
                                <Link to="/airport/list" state={{terminal: 1, depth: 0}}>
                                    Shopping
                                </Link>
                                <Link to="/airport/list" state={{terminal: 1, depth: 1}}>
                                    Restaurants
                                </Link>
                                <Link to="/airport/list" state={{terminal: 1, depth: 2}}>
                                    Service and Info desk
                                </Link>
                            </div>
                        </li>
                         <li>
                            <button className="airport-depth1">Terminal 2</button>
                            <div>
                                <Link to="/airport/list" state={{terminal: 2, depth: 0}}>
                                    Shopping
                                </Link>
                                <Link to="/airport/list" state={{terminal: 2, depth: 1}}>
                                    Restaurants
                                </Link>
                                <Link to="/airport/list" state={{terminal: 2, depth: 2}}>
                                    Service and Info desk
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

function PcComponent({t1normal, t2normal, t1free, t2free}) {
    const [terminal, setTerminal] = useState(0)
    const [depth, setDepth] = useState(0)

    function terminalSet(index) {
        setTerminal(index)
        setDepth(0)
    }

    function depthSet(index) {
        setDepth(index)
    }

    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }
    useEffect(()=>{
        window.addEventListener('scroll', updateScroll);
    }, []);
    return (
        <>
            <div className="airport-bottom contents-wrap inner">
                <div className={scrollPosition < 397 ?"airport-left" :"airport-left fixed"}>
                    <p>Incheon International Airport</p>
                    <ul>
                    <li className={terminal == 0 ? "active" : ""}>
                            <button className="airport-depth1" onClick={() => terminalSet(0)}>Airport Info</button>
                        </li>
                        <li className={terminal == 1 ? "active" : ""}>
                            <button className="airport-depth1" onClick={() => terminalSet(1)}>Terminal 1</button>
                            <div>
                                <button className={terminal == 1 && depth == 0 ? "active" : ""} onClick={() => depthSet(0)}>
                                    Shopping
                                </button>
                                <button className={terminal == 1 && depth == 1 ? "active" : ""}onClick={() => depthSet(1)}>
                                    Restaurants
                                </button>
                                <button className={terminal == 1 && depth == 2 ? "active" : ""}onClick={() => depthSet(2)}>
                                    Service and Info desk
                                </button>
                            </div>
                        </li>
                        <li className={terminal == 2 ? "active" : ""}>
                            <button className="airport-depth1" onClick={() => terminalSet(2)}>Terminal 2</button>
                            <div>
                                <button className={terminal == 2 && depth == 0 ? "active" : ""}onClick={() => depthSet(0)}>
                                    Shopping
                                </button>
                                <button className={terminal == 2 && depth == 1 ? "active" : ""}onClick={() => depthSet(1)}>
                                     Restaurants
                                </button>
                                <button className={terminal == 2 && depth == 2 ? "active" : ""}onClick={() => depthSet(2)}>
                                    Service and Info desk
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='airport-right'>
                    {terminal == 0 ? <DetailAirport /> : depth == 0 ? <DetailShopping terminal={terminal} scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} /> : depth == 1 ? <DetailFood terminal={terminal} scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} /> : <DetailService terminal={terminal} scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} />}
                </div>
            </div>
        </>
    )
}

function DetailAirport(props) {
    let terminal = props.terminal

    const { kakao } = window

    useEffect(() => {
        const container = document.querySelector(".airport-info-middle .left")
        const options = {
            center: new kakao.maps.LatLng(37.44939517488918, 126.45039546214956),
            level: 4,
        }

        const map = new kakao.maps.Map(container, options)
    }, [])

    console.log(weatherResult1)

    return (
        <>
            <div className='airport-info-top'>
                <img src="../img/airport_info.jpg" alt="Airport Photo" />
            </div>
            <div className='airport-info-mo'>
                <div className='wrap-img'>
                    <img src="../img/airport_info.jpg" alt="Airport Photo" />
                </div>
                <div className='wrap'>
                    <h3>Incheon Airport</h3>
                    <div className='weather'>
                        <div className='left'>
                            the weather in <br/>Incheon is...
                        </div>
                        <div className='right'>
                            <div>
                                <span>{weatherResult2.obsrValue}</span>
                                ℃</div>
                            <p><span>{weatherResult1[1].fcstValue}</span>/<span>{weatherResult1[0].fcstValue}</span></p>
                        </div>
                    </div>
                    <ul>
                        <li>duty free shop 06:30 ~ 21:30</li>
                        <li>restaurants 06:00 ~ 22:00</li>
                        <li className='no'>(few shops run 24hrs)</li>
                    </ul>
                </div>
            </div>
            <div className='airport-info-middle'>
                <h3>Arriving</h3>
                <div className='left'></div>
                <div className='right'>
                    <p>
                        <span>Subway</span><br/>
                        A'REX Express train
                    </p>
                    <div>
                        <p className='mb-0'><span>Bus</span></p>
                        <p>
                        ticketing offices<br/>
                        Terminal 1 : indoor(next to exits 4 and 9),<br/>outdoor(near exits 4, 6, 7, 8, 11, and 13)<br/>
                        Terminal 2 : indoors on The 2nd Transportation Center (B1)
                        <br/><br/>
                        Seoul<br/>
                        6001, 6002, 6003, 6004, 6005, 6006, 6007, 6008, 6009, 6010, 6011, 6012, 6013, 6014, 6015, 6016, 6017, 6018, 6019, 6020, 6100, 6102, 6103, 6200, 6300, 6600, 6701,6702, 6703, 6705A, 6705B, N6701, N6703
                        </p>
                        <p>
                        Gyeonggi<br/>
                        3200, 3300, 4000, 4100, 4100-2, 4200, 4200-1, 5000, 5300, 5400(Dankook University), 5400(Ori Station), 5600, 6770, 7000, 7000A, 7001, 7200, 7400, 7500, 8822, 8829, 8834, 8835, 8837, 8842, 8843, 8844, 8849, 8852, 8877, 9000, N4000, N5300, N7000, N7001, N7200
                        </p>
                    </div>
                    <div className='btn-area right'>
                        <a href='https://map.kakao.com/?map_type=TYPE_MAP&target=car&rt=,,378427,1097589&rt1=&rt2=%EC%9D%B8%EC%B2%9C%EA%B5%AD%EC%A0%9C%EA%B3%B5%ED%95%AD&rtIds=,10751028' className='btn btn-green-border' target="_blank">Get Directions</a>
                    </div>
                </div>
            </div>
            <div className='airport-info-bottom'>
                <div className='left'>
                    <p className='title'>Shuttle Bus</p>
                    <p className='flex'>
                        <span>Terminal 1 → terminal 2 : </span> 3F Center(Near 8)
                    </p>
                    <p className='flex'>
                        <span>Terminal 2 → terminal 1 : </span> 3F Center(Near 4~5)
                    </p>
                </div>
                <div className='right'>
                    <p className='title'>Emergency</p>
                    <p className='explain'>
                        When you feel sick, need assistance, lost your belongings or people you were with, please check
                    </p>
                    <p>
                        When you are sick<br/>
                        Call 119
                    </p>
                    <p className='pr-30'>
                        Search for the info desk<br/>
                        T1) Near Gate 25 on 3F, Terminal 1 / Near Gate 29 on 3F, Terminal 1<br/>
                        T2) Near Departure Hall 1 on 3F / Terminal 2, Near Exit 5 on 1F, Terminal 2 
                    </p>
                </div>
            </div>
        </>
    )
}

function DetailShopping({terminal, scrollPosition, setScrollPosition, t1normal, t2normal, t1free, t2free }) {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)

    function showModal(item) {
        setModalOpen(true)
        setModalData(item)
    }

    let data1 = terminal == 1 ? t1normal : t2normal
    let data2 = terminal == 1 ? t1free : t2free

    //데이터 가공
    let shop1_1 = data1.filter((item) => item.mcategorynm == "Convenience Store / Packaged Food")
    let shop1_2 = data2.filter((item) => item.mcategorynm == "Convenience Store / Packaged Food")

    let shop1 = shop1_1.concat(shop1_2)
    
    let shop2_1 = data1.filter((item) => item.mcategorynm == 'Bookstore')
    let shop2_2 = data2.filter((item) => item.mcategorynm == 'Bookstore')

    let shop2 = shop2_1.concat(shop2_2)
    
    let shop3_1 = data1.filter((item) => item.mcategorynm == "Fashion")
    let shop3_2 = data2.filter((item) => item.mcategorynm == "Fashion")
    
    let shop5_1 = data1.filter((item) => item.mcategorynm == "Cosmetics")
    let shop5_2 = data2.filter((item) => item.mcategorynm == "Cosmetics")

    let shop3 = shop3_1.concat(shop3_2).concat(shop5_1).concat(shop5_2)
    
    let shop4_1 = data1.filter((item) => item.mcategorynm == "Travel goods")
    let shop4_2 = data2.filter((item) => item.mcategorynm == "Travel goods")

    let shop4 = shop4_1.concat(shop4_2)
    
    let shop6_1 = data1.filter((item) => item.mcategorynm == "Souvenirs")
    let shop6_2 = data2.filter((item) => item.mcategorynm == "Souvenirs")
    
    let shop7_1 = data1.filter((item) => item.mcategorynm == "Flowers")
    let shop7_2 = data2.filter((item) => item.mcategorynm == "Flowers")

    let shop6 = shop6_1.concat(shop6_2).concat(shop7_1).concat(shop7_2)

    let shop8_1 = data2.filter((item) => item.scategorynm == "Liquor/Tobacco")
    let shop8_2 = data2.filter((item) => item.scategorynm == "Liquor/Tobacco")

    let shop8 = shop8_1.concat(shop8_2)

    let shop9_1 = data2.filter((item) => item.scategorynm == "luxury brand")
    let shop9_2 = data2.filter((item) => item.scategorynm == "luxury brand")

    let shop9 = shop9_1.concat(shop9_2)

    let shop10_1 = data2.filter((item) => item.scategorynm == "Cosmetics/Perfumes")
    let shop10_2 = data2.filter((item) => item.scategorynm == "Cosmetics/Perfumes")

    let shop10 = shop10_1.concat(shop10_2)

    let shop11_1 = data2.filter((item) => item.scategorynm == "Electronics")
    let shop11_2 = data2.filter((item) => item.scategorynm == "Electronics")

    let shop11 = shop11_1.concat(shop11_2)

    let shop12_1 = data2.filter((item) => item.scategorynm == "Packaged Foods")
    let shop12_2 = data2.filter((item) => item.scategorynm == "Packaged Foods")

    let shop12 = shop12_1.concat(shop12_2)

    let shop13_1 = data2.filter((item) => item.scategorynm == "Child")
    let shop13_2 = data2.filter((item) => item.scategorynm == "Child")

    let shop13 = shop13_1.concat(shop13_2)

    let shop14_1 = data2.filter((item) => item.scategorynm == "Other")
    let shop14_2 = data2.filter((item) => item.scategorynm == "Other")

    let shop14 = shop14_1.concat(shop14_2)


    //이동
    const conShop1 = React.useRef(null);
    const conShop2 = React.useRef(null);
    const conShop3 = React.useRef(null);
    const conShop4 = React.useRef(null);
    const conShop5 = React.useRef(null);
    const conShop6 = React.useRef(null);
    const conShop7 = React.useRef(null);
    const conShop8 = React.useRef(null);
    const conShop9 = React.useRef(null);
    const conShop10 = React.useRef(null);
    const conShop11 = React.useRef(null);
    const conShop12 = React.useRef(null);

    let contentsArr = [conShop1,conShop2,conShop3,conShop4,conShop5,conShop6,conShop7,conShop8,conShop9,conShop10,conShop11,conShop12]

    function scroll(index) {
        contentsArr[index].current.scrollIntoView({ behavior: 'smooth'});
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    useEffect(() => {
        let wrapArr = document.querySelectorAll('.airport-shopping-r .wrap')
    
        let heightArr = []
    
        for(let i = 0; i < wrapArr.length; i++) {
            let height = wrapArr[i].offsetHeight + 45
    
            if(i == 0) {
                heightArr.push(height+397)
            } else {
                height = height + heightArr[i-1] + 40
                heightArr.push(height)
            }
        }

        function scrollChange() {
            let nowHeight = window.scrollY || document.documentElement.scrollTop
            let wrap = document.querySelector('.airport-shopping-l.long .wrap')
            if(wrap) {
                if(nowHeight > 9376) {
                    wrap.style.maxHeight = `${670 - (nowHeight - 9376)}px`
                } else {
                    wrap.style.maxHeight = ""
                }
            }
            for(let i = 0; i < heightArr.length; i++) {
                if(nowHeight < heightArr[i]) {
                    let button = document.querySelectorAll('.airport-shopping-l .wrap button')
                    for(let j = 0; j < button.length; j++) {
                        button[j].classList.remove('active')
                    }
                    if(button[i]) {
                        button[i].classList.add('active')
                    }
                    return
                }
            }
        }
        
        window.addEventListener('scroll', scrollChange)
    }, [])

    return (
        <>
            {modalOpen && <Modal setModalOpen={setModalOpen} data={modalData} />}
            <div className='airport-shopping'>
                <div className={scrollPosition < 397 ?"airport-shopping-l long" :"airport-shopping-l long fixed"}>
                    <div className='wrap'>
                        <button onClick={() => scroll(0)} className="active">
                            Convenience store/<br/>
                            Packaged food
                        </button>
                        <button onClick={() => scroll(1)}>Bookstore</button>
                        <button onClick={() => scroll(2)}>Fashion</button>
                        <button onClick={() => scroll(3)}>Travel goods</button>
                        <button onClick={() => scroll(4)}>Souvenirs/Flowers</button>
                        <button onClick={() => scroll(5)}>Liquor/Tobacco</button>
                        <button onClick={() => scroll(6)}>Luxury Brand</button>
                        <button onClick={() => scroll(7)}>Cosmetics/Perfumes</button>
                        <button onClick={() => scroll(8)}>Electronics</button>
                        <button onClick={() => scroll(9)}>Packaged Foods</button>
                        <button onClick={() => scroll(10)}>Kids</button>
                        <button onClick={() => scroll(11)}>Other</button>
                    </div>
                </div>
                <div className='airport-shopping-r'>
                    <h4 id='shop1' ref={conShop1}>Convenience stor/Packaged food</h4>
                    <div className='wrap'>
                        {shop1.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop2' ref={conShop2}>Bookstore</h4>
                    <div className='wrap'>
                        {shop2.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop3' ref={conShop3}>Fashion</h4>
                    <div className='wrap'>
                        {shop3.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop4' ref={conShop4}>Travel goods</h4>
                    <div className='wrap'>
                        {shop4.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop5' ref={conShop5}>Souvenirs/Flowers</h4>
                    <div className='wrap'>
                        {shop6.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop6' ref={conShop6}>Liquor/Tobacco</h4>
                    <div className='wrap'>
                        {shop8.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop7' ref={conShop7}>Luxury Brand</h4>
                    <div className='wrap'>
                        {shop9.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop8' ref={conShop8}>Cosmetics/Perfumes</h4>
                    <div className='wrap'>
                        {shop10.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop9' ref={conShop9}>Electronics</h4>
                    <div className='wrap'>
                        {shop11.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop10' ref={conShop10}>Packaged Foods</h4>
                    <div className='wrap'>
                        {shop12.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop11' ref={conShop11}>Kids</h4>
                    <div className='wrap'>
                        {shop13.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop12' ref={conShop12}>Other</h4>
                    <div className='wrap'>
                        {shop14.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

function DetailFood({terminal, scrollPosition, setScrollPosition, t1normal, t2normal, t1free, t2free }) {

    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)

    function showModal(item) {
        setModalOpen(true)
        setModalData(item)
    }

    let data1 = terminal == 1 ? t1normal : t2normal
    let data2 = terminal == 1 ? t1free : t2free

    //데이터 가공
    let shop1_1 = data1.filter((item) => item.mcategorynm == "Korean Food ")
    let shop1_2 = data2.filter((item) => item.mcategorynm == "Korean Food ")

    let shop1 = shop1_1.concat(shop1_2)
    
    let shop2_1 = data1.filter((item) => item.mcategorynm == "Western")
    let shop2_2 = data2.filter((item) => item.mcategorynm == "Western")

    let shop2 = shop2_1.concat(shop2_2)
    
    let shop3_1 = data1.filter((item) => item.mcategorynm == "Asian")
    let shop3_2 = data2.filter((item) => item.mcategorynm == "Asian")
    
    let shop4_1 = data1.filter((item) => item.mcategorynm == "Fast Food")
    let shop4_2 = data2.filter((item) => item.mcategorynm == "Fast Food")

    let shop3 = shop3_1.concat(shop3_2)

    let shop4 = shop4_1.concat(shop4_2)
    
    let shop5_1 = data1.filter((item) => item.mcategorynm == "Food Court")
    let shop5_2 = data2.filter((item) => item.mcategorynm == "Food Court")

    let shop5 = shop5_1.concat(shop5_2)
    
    let shop6_1 = data1.filter((item) => item.mcategorynm == "Snack / Cafe / Dessert")
    let shop6_2 = data2.filter((item) => item.mcategorynm == "Snack / Cafe / Dessert")

    let shop6 = shop6_1.concat(shop6_2)


    //이동
    const conShop1 = React.useRef(null);
    const conShop2 = React.useRef(null);
    const conShop3 = React.useRef(null);
    const conShop4 = React.useRef(null);
    const conShop5 = React.useRef(null);
    const conShop6 = React.useRef(null);

    let contentsArr = [conShop1,conShop2,conShop3,conShop4,conShop5,conShop6]

    function scroll(index) {
        contentsArr[index].current.scrollIntoView({ behavior: 'smooth'});
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    useEffect(() => {
        let wrapArr = document.querySelectorAll('.airport-shopping-r .wrap')
    
        let heightArr = []
    
        for(let i = 0; i < wrapArr.length; i++) {
            let height = wrapArr[i].offsetHeight + 45
    
            if(i == 0) {
                heightArr.push(height+397)
            } else {
                height = height + heightArr[i-1] + 40
                heightArr.push(height)
            }
        }

        function scrollChange() {
            let nowHeight = window.scrollY || document.documentElement.scrollTop
            for(let i = 0; i < heightArr.length; i++) {
                if(nowHeight < heightArr[i]) {
                    let button = document.querySelectorAll('.airport-shopping-l .wrap button')
                    for(let j = 0; j < button.length; j++) {
                        button[j].classList.remove('active')
                    }
                    if(button[i]) {
                        button[i].classList.add('active')
                    }
                    return
                }
            }
        }
        
        window.addEventListener('scroll', scrollChange)
    }, [])

    return (
        <>
            {modalOpen && <Modal setModalOpen={setModalOpen} data={modalData} />}
            <div className='airport-shopping'>
                <div className={scrollPosition < 397 ?"airport-shopping-l" :"airport-shopping-l fixed"}>
                    <div className='wrap'>
                        <button onClick={() => scroll(0)} className="active">
                            Korean Food
                        </button>
                        <button onClick={() => scroll(1)}>Western Food</button>
                        <button onClick={() => scroll(2)}>Asian Food</button>
                        <button onClick={() => scroll(3)}>Fast Food</button>
                        <button onClick={() => scroll(4)}>Food Court</button>
                        <button onClick={() => scroll(5)}>Snack/Cafe</button>
                    </div>
                </div>
                <div className='airport-shopping-r'>
                    <h4 id='shop1' ref={conShop1}>Korean Food</h4>
                    <div className='wrap'>
                        {shop1.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop2' ref={conShop2}>Western Food</h4>
                    <div className='wrap'>
                        {shop2.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop3' ref={conShop3}>Asian Food</h4>
                    <div className='wrap'>
                        {shop3.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop4' ref={conShop4}>Fast Food</h4>
                    <div className='wrap'>
                        {shop4.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop5' ref={conShop5}>Food Court</h4>
                    <div className='wrap'>
                        {shop5.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop6' ref={conShop6}>Snack/Cafe</h4>
                    <div className='wrap'>
                        {shop6.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

function DetailService({terminal, scrollPosition, setScrollPosition, t1normal, t2normal, t1free, t2free}) {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)

    function showModal(item) {
        setModalOpen(true)
        setModalData(item)
    }

    let data1 = terminal == 0 ? t1normal : t2normal
    let data2 = terminal == 0 ? t1free : t2free

    let shop1_1 = data1.filter((item) => item.lcategorynm == "공항즐기기")
    let shop1_2 = data2.filter((item) => item.lcategorynm == "공항즐기기")

    let shop1 = shop1_1.concat(shop1_2)

    let shop2_1 = data1.filter((item) => item.mcategorynm == "Medical/Children" && item.scategorynm != "Children")
    let shop2_2 = data2.filter((item) => item.mcategorynm == "Medical/Children" && item.scategorynm != "Children")

    let shop2 = shop2_1.concat(shop2_2)

    let shop3_1 = data1.filter((item) => item.mcategorynm == "Information Desk")
    let shop3_2 = data2.filter((item) => item.mcategorynm == "Information Desk")

    let shop3 = shop3_1.concat(shop3_2)

    let shop4_1 = data1.filter((item) => item.mcategorynm == "Lounge / Hotel / Rest")
    let shop4_2 = data2.filter((item) => item.mcategorynm == "Lounge / Hotel / Rest")

    let shop4 = shop4_1.concat(shop4_2)

    let shop5_1 = data1.filter((item) => item.mcategorynm == "기타편의")
    let shop5_2 = data2.filter((item) => item.mcategorynm == "기타편의")

    let shop5 = shop5_1.concat(shop5_2)


    //이동
    const conShop1 = React.useRef(null);
    const conShop2 = React.useRef(null);
    const conShop3 = React.useRef(null);
    // const conShop4 = React.useRef(null);
    // const conShop5 = React.useRef(null);
    let contentsArr = [conShop1,conShop2,conShop3]

    function scroll(index) {
        contentsArr[index].current.scrollIntoView({ behavior: 'smooth'});
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }


    useEffect(() => {
        let wrapArr = document.querySelectorAll('.airport-shopping-r .wrap')
    
        let heightArr = []
    
        for(let i = 0; i < wrapArr.length; i++) {
            let height = wrapArr[i].offsetHeight + 45
    
            if(i == 0) {
                heightArr.push(height+397+75+15)
            } else {
                height = height + heightArr[i-1] + 40
                heightArr.push(height)
            }
        }
    
        function scrollChange() {
            let nowHeight = window.scrollY || document.documentElement.scrollTop
            for(let i = 0; i < heightArr.length; i++) {
                if(nowHeight < heightArr[i]) {
                    let button = document.querySelectorAll('.airport-shopping-t button')
                    for(let j = 0; j < button.length; j++) {
                        button[j].classList.remove('active')
                    }
                    if(button[i]) {
                        button[i].classList.add('active')
                    }
                    return
                }
            }
        }

        window.addEventListener('scroll', scrollChange)
    }, [])

    return (
        <>
            {modalOpen && <Modal setModalOpen={setModalOpen} data={modalData} />}
            <div className={scrollPosition < 397 ?"airport-shopping-t" :"airport-shopping-t fixed"}>
                <button onClick={() => scroll(0)} className="active">
                    Information Desk
                </button>
                <button onClick={() => scroll(1)}>
                    Airport Lounge
                </button>
                <button onClick={() => scroll(2)}>
                    Emergency Kit
                </button>
            </div>
            <div className='airport-shopping-r'>
                    <h4 id='shop1' ref={conShop1}>Information Desk</h4>
                    <div className='wrap'>
                        {shop3.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop2' ref={conShop2}>Airport Lounge</h4>
                    <div className='wrap'>
                        {shop4.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <h4 id='shop3' ref={conShop3}>Emergency Kit</h4>
                    <div className='wrap'>
                        {shop2.map((item, index) => {
                            return (
                                <div className='item' key={index} onClick={() => showModal(item)}>
                                    <p className='title'>
                                        {item.facilitynm}
                                    </p>
                                    <div>
                                        <span>{item.floorinfo}</span>
                                        <span>{item.servicetime}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
            </div>
        </>
    )
}

function Modal({setModalOpen, data}) {
    function closeModal() {
        setModalOpen(false)
    }

    return (
        <>
            <div className='modal'>
                <button className='close'><img src='../img/close.svg' alt="close the popup" onClick={closeModal} /></button>
                <p className='title'>{data.facilitynm}</p>
                <div className='bottom'>
                    <p>
                        <span>Location</span>
                        {data.lcnm}
                    </p>
                    <p>
                        <span>Facility item</span>
                        {data.facilityitem}
                    </p>
                </div>
            </div>
        </>
    )
}

export default Airport;