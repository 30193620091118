import { Link } from 'react-router-dom'

function Themedtour() {
    return (
        <>
            <div className="theme-main">
                <div className="banner contents-wrap">
                    <h2>Themed Tour</h2>
                    <p className='text-center'>
                        These themes are chosen to ease your trip, but also make it high quality. Our four themes will show you all around Incheon, and make it more exciting!
                    </p>
                </div>
                <div className='theme-choose contents-wrap'>Choose a theme</div>
                <div className='theme-link contents-wrap'>
                    <Link to="/themes/list" state={"city"}>
                        City Seeker's Journey
                    </Link>
                    <Link to="/themes/list" state={"food"}>
                        Foodie Trip
                    </Link>
                    <Link to="/themes/list" state={"history"}>
                        Jump Back Time : History Tour
                    </Link>
                    <Link to="/themes/list" state={"nature"}>
                        A Walk Into The Nature
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Themedtour;