import { useState } from 'react';

import CafeList from './food/CafeList';
import ResList from './food/ResList';

import { foodData } from '../Api'

function Cafe() {
    const [now, setNow] = useState(0)

    let data = foodData.items.item

    let cafeData = data.filter((item) => item.cat3 == 'A05020900')
    let resData = data.filter((item) => item.cat3 != 'A05020900')

    function change(numMenu) {
        setNow(numMenu)
    }

    function toTop() {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className='food-top'>
                <button className={now == 0 ? 'food-btn active' : 'food-btn'} onClick={() => change(0)}>CAFÉ</button>
                <button className={now == 1 ? 'food-btn active' : 'food-btn'} onClick={() => change(1)}>RESTAURANT</button>
            </div>
            {now == 0 ? <CafeList contents={cafeData} /> : <ResList contents={resData} />}
            <button className='btn-top' onClick={toTop}>
                TOP
            </button>
        </>
    )
}

export default Cafe;