import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import { courseContents, courseInfo, courseDetail } from '../../Api'

import data from './data'

const { kakao } = window
function ThemeDetail() {
    const location = useLocation();
    const propsData = location.state;
    let navigate = useNavigate();

    const [title, setTitle] = useState(null)
    const [thumb, setThumb] = useState(null)
    const [overview, setOverview] = useState(null)
    const [distance, setDistance] = useState(null)
    const [time, setTime] = useState(null)
    const [contentArr, setContentArr] = useState([])

    useEffect(() => {
        async function api() {
            let detail = await courseDetail(propsData)
            let info = await courseInfo(propsData)
            let contents = await courseContents(propsData)

            setTitle(detail[0].title)
            setThumb(detail[0].firstimage)
            setOverview(detail[0].overview)

            setDistance(info[0].distance)
            setTime(info[0].taketime)

            setContentArr(contents)

            let markerArr = data.map[propsData]

            const container = document.querySelector(".theme-map")
            const options = {
                center: new kakao.maps.LatLng(markerArr[0][1], markerArr[0][0]),
                level: 9,
            }
            const map = new kakao.maps.Map(container, options)

            let positions = []
            for(let i = 0; i < markerArr.length; i++) {
                let obj = {
                    latlng: new kakao.maps.LatLng(markerArr[i][1], markerArr[i][0])
                }
                positions.push(obj)
            }

            for (var i = 0; i < positions.length; i ++) {
                let imageSrc = `./img/marker_theme_${i+1}.png`
                // 마커 이미지의 이미지 크기 입니다
                var imageSize = new kakao.maps.Size(49, 60); 
                
                // 마커 이미지를 생성합니다    
                var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize); 
                
                // 마커를 생성합니다
                var marker = new kakao.maps.Marker({
                    map: map, // 마커를 표시할 지도
                    position: positions[i].latlng,
                    image : markerImage 
                });
            }
        }

        api()
    }, [])

    return (
        <>
            <div className="blog-detail contents-wrap inner">
                <div>
                    <button className="blog-btn" onClick={() => navigate(-1)}>
                        <img src="./img/ico_back.svg" alt="Go Back" />
                        <span>Back</span>
                    </button>
                </div>
                
                <h3 className="detail-title">
                    {title}
                </h3>
                <div className="detail-contents noborder">
                    <p>
                        <img src={thumb ? thumb : "./img/noimage.png"} alt={title} />
                    </p>
                    <ul className='theme-info'>
                        <li><img src='../img/distance.png' alt='Distance' />{distance}</li>
                        <li><img src='../img/clock.png' alt='Duration of time' />{time}</li>
                    </ul>
                    <p>
                        {overview}
                    </p>
                    <div className='theme-detail-wrap'>
                        {contentArr.map((item, index) => {
                            return (
                                <div className='theme-detail' key={index}>
                                    <div className='left'>
                                        <img src={item.subdetailimg ? item.subdetailimg : "./img/noimage.png"} alt={item.subname} />
                                        <span className='notranslate'>{index + 1}</span>
                                    </div>
                                    <div className='right'>
                                        <h4>{item.subname}</h4>
                                        <p>{item.subdetailoverview}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='theme-map'></div>
                </div>
            </div>
        </>
    )
}

export default ThemeDetail