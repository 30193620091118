import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Station() {
    const [list, setList] = useState([
        {
            name: 'Seoul',
            img: 'line_station_1.jpg'
        },
        {
            name: 'Gongdeok',
            img: 'line_station_2.jpg'
        },
        {
            name: 'Hongik Univ.',
            img: 'line_station_3.jpg'
        }
    ])
    const [isFirst, setIsFirst] = useState(true)
    const [fade, setFade] = useState(true)

    let arr = [
        {
            name: 'Seoul',
            img: 'line_station_1.jpg'
        },
        {
            name: 'Gongdeok',
            img: 'line_station_2.jpg'
        },
        {
            name: 'Hongik Univ.',
            img: 'line_station_3.jpg'
        },
        {
            name: 'Digital Media City',
            img: 'line_station_4.jpg'
        },
        {
            name: 'Magongnaru',
            img: 'line_station_5.jpg'
        },
        {
            name: 'Unseo',
            img: 'line_station_9.jpg'
        }
    ]

    useEffect(() => {
        const timeout = setInterval(() => {
            // setFade(true)
            if (isFirst) {
                setList([
                    {
                        name: 'Seoul',
                        img: 'line_station_1.jpg'
                    },
                    {
                        name: 'Gongdeok',
                        img: 'line_station_2.jpg'
                    },
                    {
                        name: 'Hongik Univ.',
                        img: 'line_station_3.jpg'
                    }
                ])
                setIsFirst(false)
            } else {
                setList([
                    {
                        name: 'Digital Media City',
                        img: 'line_station_4.jpg'
                    },
                    {
                        name: 'Magongnaru',
                        img: 'line_station_5.jpg'
                    },
                    {
                        name: 'Unseo',
                        img: 'line_station_9.jpg'
                    }
                ])
                setIsFirst(true)
            }
            function removeFade() {
                setFade(false)
            }
            setTimeout(removeFade, 1000)
        }, 5000);
   
   return () => clearInterval(timeout)
   }, [isFirst])

    return (
        <>
            <div className="contents-wrap main-contents stations" data-aos="fade-up">
                <div className="stations-title">
                    A’REX<br />
                    Line<br />
                    Stations
                </div>
                <ul className={fade ? "stations-list fade" : "stations-list"}>
                    {
                        list.map((item, index) => {
                            let src = `./img/${item.img}`
                            let alt = `${item.name} station image`

                            if (index > 2) {
                                return
                            }

                            return (
                                <Link to="/arex" state={item.name} key={item.name}>
                                    <li className="stations-item">
                                        <div className="top">
                                            <img src={src} alt={alt} />
                                        </div>
                                        <div className="bottom">
                                            {item.name} Station
                                        </div>
                                    </li>
                                </Link>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default Station