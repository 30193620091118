import { useState } from 'react';

import All from './blog/All'
import Another from './blog/Another'
import Detail from './blog/Detail'

function Blog() {
    return (
        <>
            <Banner />
            <Menu />
        </>
    )
}

function Banner() {
    return (
        <>
            <div className="banner blog">
                <div className="contents-wrap">
                    <span>blog</span>
                    <h2>Travel stories and tips</h2>
                    <p>
                    Articles written by our team will give you<br />
                    new perspectives and ideas
                    </p>
                </div>
            </div>
        </>
    )
}

function Menu() {
    let now = sessionStorage.getItem("blog") ? sessionStorage.getItem("blog") : "0"
    const [menu, setMenu] = useState(now)
    const [isDetail, setisDetail] = useState(false)

    let menuArr = ["All", "Recommendation", "Tips", "Culture", "Layover travelers", "Korean Food"]

    function MenuChange(index) {
        setMenu(index);
        sessionStorage.setItem("blog", index)
    }

    let onClickOpen = function onClickOpen(data) {
        setisDetail(true);

        let position = data;

        return <Detail url={position} onClickClose={onClickClose} />
    }

    let onClickClose = function onClickClose() {
        setisDetail(false);
    }

    return (
        <>
            {
                isDetail === false ?
                <div className="contents-wrap inner">
                    <ul className='blog-menu'>
                        {menuArr.map((item, index) => {
                            return (
                                <li className={index==menu?'active':''} onClick={() => MenuChange(index)} key={item}>
                                    <button>
                                        {item}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                    { menu== 0 ? <All onClickOpen={onClickOpen} /> : <Another sub={menuArr[menu]} onClickOpen={onClickOpen} /> }
                </div>
                :
               <onClickOpen />
            }
        </>
    )
}

export default Blog;