import { useState, useEffect } from "react"
import { useLocation, Link, useNavigate } from "react-router-dom"
import { airportInfoApi, airportInfoApi2 } from '../../Api'

function AirportList() {
    const location = useLocation();
    let state = location.state
    let terminal = state.terminal
    let navigate = useNavigate()

    const [depth, setDepth] = useState(state.depth)

    const [t1normal, setT1normal] = useState([])
    const [t2normal, setT2normal] = useState([])
    const [t1free, setT1free] = useState([])
    const [t2free, setT2free] = useState([])

    useEffect(() => {
        async function result() {
            let airportInfo = await airportInfoApi()
            let airportInfo2 = await airportInfoApi2()

            let t1n = airportInfo.filter((item) => item.terminalid == 'P01' || item.terminalid == 'G02')
            let t2n = airportInfo.filter((item) => item.terminalid == 'P03' || item.terminalid == 'G03')
            let t1f = airportInfo2.filter((item) => item.terminalid == 'P01' || item.terminalid == 'G02')
            let t2f = airportInfo2.filter((item) => item.terminalid == 'P03' || item.terminalid == 'G03')

            setT1normal(t1n)
            setT2normal(t2n)
            setT1free(t1f)
            setT2free(t2f)
        }

        result()
    }, [])
    
    return (
        <>
            <div className="airport-top">
                <div className="banner airport contents-wrap detail">
                    <h2>Terminal {terminal == 1 ? "One" : "Two"}</h2>
                </div>
                <button className="btn-back" onClick={() => navigate(-1)}>
                    <span><img src="./img/ico_back.svg" alt="Go Back" /></span>Back
                </button>
            </div>
            <div className="airport-bottom-detail">
                <h3>Categories</h3>
                <ul className="airport-cate">
                    <li onClick={() => setDepth(0)} className={depth == 0 ? "active" : ""} >
                        <Link to="/airport/list" state={{terminal: terminal, depth: 0}}>
                            Shopping
                        </Link>
                    </li>
                    <li onClick={() => setDepth(1)} className={depth == 1 ? "active" : ""}>
                        <Link to="/airport/list" state={{terminal: terminal, depth: 1}}>
                            Restaurants
                        </Link>
                    </li>
                    <li onClick={() => setDepth(2)} className={depth == 2 ? "active" : ""}>
                        <Link to="/airport/list" state={{terminal: terminal, depth: 2}}>
                            Service and Info desk
                        </Link>
                    </li>
                </ul>

                {depth == 0 ? <Shopping terminal={terminal} t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} /> : depth == 1 ? <Restaurant terminal={terminal} t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} /> : <Service terminal={terminal} t1normal={t1normal} t2normal={t2normal} t1free={t1free} t2free={t2free} />}
            </div>
        </>
    )
}

function Shopping({terminal, t1normal, t2normal, t1free, t2free}) {
    let data1 = terminal == 1 ? t1normal : t2normal
    let data2 = terminal == 1 ? t1free : t2free

    //데이터 가공
    let shop1_1 = data1.filter((item) => item.mcategorynm == "Convenience Store / Packaged Food")
    let shop1_2 = data2.filter((item) => item.mcategorynm == "Convenience Store / Packaged Food")

    let shop1 = shop1_1.concat(shop1_2)
    
    let shop2_1 = data1.filter((item) => item.mcategorynm == 'Bookstore')
    let shop2_2 = data2.filter((item) => item.mcategorynm == 'Bookstore')

    let shop2 = shop2_1.concat(shop2_2)
    
    let shop3_1 = data1.filter((item) => item.mcategorynm == "Fashion")
    let shop3_2 = data2.filter((item) => item.mcategorynm == "Fashion")
    
    let shop5_1 = data1.filter((item) => item.mcategorynm == "Cosmetics")
    let shop5_2 = data2.filter((item) => item.mcategorynm == "Cosmetics")

    let shop3 = shop3_1.concat(shop3_2).concat(shop5_1).concat(shop5_2)
    
    let shop4_1 = data1.filter((item) => item.mcategorynm == "Travel goods")
    let shop4_2 = data2.filter((item) => item.mcategorynm == "Travel goods")

    let shop4 = shop4_1.concat(shop4_2)
    
    let shop6_1 = data1.filter((item) => item.mcategorynm == "Souvenirs")
    let shop6_2 = data2.filter((item) => item.mcategorynm == "Souvenirs")
    
    let shop7_1 = data1.filter((item) => item.mcategorynm == "Flowers")
    let shop7_2 = data2.filter((item) => item.mcategorynm == "Flowers")

    let shop5 = shop6_1.concat(shop6_2).concat(shop7_1).concat(shop7_2)

    let shop8_1 = data2.filter((item) => item.scategorynm == "Liquor/Tobacco")
    let shop8_2 = data2.filter((item) => item.scategorynm == "Liquor/Tobacco")

    let shop6 = shop8_1.concat(shop8_2)

    let shop9_1 = data2.filter((item) => item.scategorynm == "luxury brand")
    let shop9_2 = data2.filter((item) => item.scategorynm == "luxury brand")

    let shop7 = shop9_1.concat(shop9_2)

    let shop10_1 = data2.filter((item) => item.scategorynm == "Cosmetics/Perfumes")
    let shop10_2 = data2.filter((item) => item.scategorynm == "Cosmetics/Perfumes")

    let shop8 = shop10_1.concat(shop10_2)

    let shop11_1 = data2.filter((item) => item.scategorynm == "Electronics")
    let shop11_2 = data2.filter((item) => item.scategorynm == "Electronics")

    let shop9 = shop11_1.concat(shop11_2)

    let shop12_1 = data2.filter((item) => item.scategorynm == "Packaged Foods")
    let shop12_2 = data2.filter((item) => item.scategorynm == "Packaged Foods")

    let shop10 = shop12_1.concat(shop12_2)

    let shop13_1 = data2.filter((item) => item.scategorynm == "Child")
    let shop13_2 = data2.filter((item) => item.scategorynm == "Child")

    let shop11 = shop13_1.concat(shop13_2)

    let shop14_1 = data2.filter((item) => item.scategorynm == "Other")
    let shop14_2 = data2.filter((item) => item.scategorynm == "Other")

    let shop12 = shop14_1.concat(shop14_2)

    const [nowData, setNowData] = useState([])

    let dataArr = [shop1,shop2,shop3,shop4,shop5,shop6,shop7,shop8,shop9,shop10,shop11,shop12]

    function change(index) {
        setNowData(dataArr[index])
    }

    function toggle() {
        document.querySelector('.airport-bottom-menu .title').classList.toggle('active')
        if(document.querySelector('.airport-bottom-menu .title').classList.contains('active')) {
            document.querySelector('.airport-bottom-menu').classList.add('active')
        } else {
            document.querySelector('.airport-bottom-menu').classList.remove('active')
        }
    }

    useEffect(() => {
        setNowData(shop1)
    }, [t1normal])

    return (
        <>
            <div className="airport-bottom-menu">
                <p className="title" onClick={toggle}>Menu</p>
                <p>Airport Shopping</p>
                <ul>
                    <li onClick={() => change(0)}>
                        <img src="./img/shopping_1.png" alt="Convenience store/Packaged food" />
                    </li>
                    <li onClick={() => change(1)}>
                        <img src="./img/shopping_2.png" alt="Bookstore" />
                    </li>
                    <li onClick={() => change(2)}>
                        <img src="./img/shopping_3.png" alt="Fashion" />
                    </li>
                    <li onClick={() => change(3)}>
                        <img src="./img/shopping_4.png" alt="Travel goods" />
                    </li>
                    <li onClick={() => change(4)}>
                        <img src="./img/shopping_5.png" alt="Souvenirs/Flowers" />
                    </li>
                </ul>
                <p>Duty Free Shopping</p>
                <ul className="seven">
                    <li onClick={() => change(5)}>
                        <img src="./img/shopping_6.png" alt="Liquor/Tobacco" />
                    </li>
                    <li onClick={() => change(6)}>
                        <img src="./img/shopping_7.png" alt="Luxury Brand" />
                    </li>
                    <li onClick={() => change(7)}>
                        <img src="./img/shopping_8.png" alt="Cosmetics/Perfumes" />
                    </li>
                    <li onClick={() => change(8)}>
                        <img src="./img/shopping_9.png" alt="Electronics" />
                    </li>
                    <li onClick={() => change(9)}>
                        <img src="./img/shopping_10.png" alt="Packaged Foods" />
                    </li>
                    <li onClick={() => change(10)}>
                        <img src="./img/shopping_11.png" alt="Kids" />
                    </li>
                    <li onClick={() => change(11)}>
                        <img src="./img/shopping_12.png" alt="Other" />
                    </li>
                </ul>
            </div>
            <div className="airport-bottom-list">
                {nowData.length > 0 ? nowData.map((item, index) => {
                    return (
                        <AirportItem item={item} key={index} />
                    )
                }) : "There is no Data"}
            </div>
        </>
    )
}

function Restaurant({terminal, t1normal, t2normal, t1free, t2free}) {
    let data1 = terminal == 1 ? t1normal : t2normal
    let data2 = terminal == 1 ? t1free : t2free

    //데이터 가공
    let shop1_1 = data1.filter((item) => item.mcategorynm == "Korean Food ")
    let shop1_2 = data2.filter((item) => item.mcategorynm == "Korean Food ")

    let shop1 = shop1_1.concat(shop1_2)
    
    let shop2_1 = data1.filter((item) => item.mcategorynm == "Western")
    let shop2_2 = data2.filter((item) => item.mcategorynm == "Western")

    let shop2 = shop2_1.concat(shop2_2)
    
    let shop3_1 = data1.filter((item) => item.mcategorynm == "Asian")
    let shop3_2 = data2.filter((item) => item.mcategorynm == "Asian")
    
    let shop4_1 = data1.filter((item) => item.mcategorynm == "Fast Food")
    let shop4_2 = data2.filter((item) => item.mcategorynm == "Fast Food")

    let shop3 = shop3_1.concat(shop3_2)

    let shop4 = shop4_1.concat(shop4_2)
    
    let shop5_1 = data1.filter((item) => item.mcategorynm == "Food Court")
    let shop5_2 = data2.filter((item) => item.mcategorynm == "Food Court")

    let shop5 = shop5_1.concat(shop5_2)
    
    let shop6_1 = data1.filter((item) => item.mcategorynm == "Snack / Cafe / Dessert")
    let shop6_2 = data2.filter((item) => item.mcategorynm == "Snack / Cafe / Dessert")

    let shop6 = shop6_1.concat(shop6_2)

    const [nowData, setNowData] = useState(shop1)

    let dataArr = [shop1,shop2,shop3,shop4,shop5,shop6]

    function change(index) {
        setNowData(dataArr[index])
    }

    function toggle() {
        document.querySelector('.airport-bottom-menu .title').classList.toggle('active')
        if(document.querySelector('.airport-bottom-menu .title').classList.contains('active')) {
            document.querySelector('.airport-bottom-menu').classList.add('active')
        } else {
            document.querySelector('.airport-bottom-menu').classList.remove('active')
        }
    }

    useEffect(() => {
        setNowData(shop1)
    }, [t1normal])

    return (
        <>
            <div className="airport-bottom-menu">
            <p className="title" onClick={toggle}>Menu</p>
                <ul>
                    <li onClick={() => change(0)}>
                        <img src="./img/res_1.png" alt="Korean Food" />
                    </li>
                    <li onClick={() => change(1)}>
                        <img src="./img/res_2.png" alt="Western Food" />
                    </li>
                    <li onClick={() => change(2)}>
                        <img src="./img/res_3.png" alt="Asian Food" />
                    </li>
                    <li onClick={() => change(3)}>
                        <img src="./img/res_4.png" alt="Fast Food" />
                    </li>
                    <li onClick={() => change(4)}>
                        <img src="./img/res_5.png" alt="Food Court" />
                    </li>
                    <li onClick={() => change(5)}>
                        <img src="./img/res_6.png" alt="Snack/Cafe" />
                    </li>
                </ul>
            </div>
            <div className="airport-bottom-list">
                {nowData.length > 0 ? nowData.map((item, index) => {
                    return (
                        <AirportItem item={item} key={index} />
                    )
                }) : "There is no Data"}
            </div>
        </>
    )
}

function Service({terminal, t1normal, t2normal, t1free, t2free}) {
    let data1 = terminal == 1 ? t1normal : t2normal
    let data2 = terminal == 1 ? t1free : t2free

    //데이터 가공
    let shop2_1 = data1.filter((item) => item.mcategorynm == "Medical/Children" && item.scategorynm != "Children")
    let shop2_2 = data2.filter((item) => item.mcategorynm == "Medical/Children" && item.scategorynm != "Children")

    let shop2 = shop2_1.concat(shop2_2)

    let shop3_1 = data1.filter((item) => item.mcategorynm == "Information Desk")
    let shop3_2 = data2.filter((item) => item.mcategorynm == "Information Desk")

    let shop1 = shop3_1.concat(shop3_2)

    let shop4_1 = data1.filter((item) => item.mcategorynm == "Lounge / Hotel / Rest")
    let shop4_2 = data2.filter((item) => item.mcategorynm == "Lounge / Hotel / Rest")

    let shop3 = shop4_1.concat(shop4_2)

    const [nowData, setNowData] = useState(shop1)

    let dataArr = [shop1,shop2,shop3]

    function change(index) {
        setNowData(dataArr[index])
    }

    function toggle() {
        document.querySelector('.airport-bottom-menu .title').classList.toggle('active')
        if(document.querySelector('.airport-bottom-menu .title').classList.contains('active')) {
            document.querySelector('.airport-bottom-menu').classList.add('active')
        } else {
            document.querySelector('.airport-bottom-menu').classList.remove('active')
        }
    }

    useEffect(() => {
        setNowData(shop1)
    }, [t1normal])

    return (
        <>
            <div className="airport-bottom-menu">
            <p className="title" onClick={toggle}>Menu</p>
                <ul>
                    <li onClick={() => change(0)}>
                        <img src="./img/service_2.png" alt="Information Desk" />
                    </li>
                    <li onClick={() => change(1)}>
                        <img src="./img/service_1.png" alt="Airport Lounge" />
                    </li>
                    <li onClick={() => change(2)}>
                        <img src="./img/service_3.png" alt="Emergency Kit" />
                    </li>
                </ul>
            </div>
            <div className="airport-bottom-list">
                {nowData.length > 0 ? nowData.map((item, index) => {
                    return (
                        <AirportItem item={item} terminal={terminal} key={index} />
                    )
                }) : "There is no Data"}
            </div>
        </>
    )
}

function AirportItem({item, terminal}) {
    return (
        <Link to="/airport/detail" state={{data: item, terminal: terminal}}>
            <div className="item notranslate">
            {item.facilitynm} {item.facilitynm == "Information Desk" ? `(${item.floorinfo})` : ""}
            <span>MORE</span>
        </div>
        </Link>
    )
}

export default AirportList