import data from './data'
import ConList from './ConList'
import { Link } from 'react-router-dom';

function All(props) {
    let main = data['main']

    return (
        <>
            <div className='blog-main'>
                <Link to="/blog/detail" state={main.contents}>
                    <div className="blog-item">
                        <ConList title={main.title} comm={main.comment} thumb={main.thumb} writer={main.writer} key={main} />
                    </div>
                </Link>
            </div>
            <h3 className='blog-sub'>Recommendation</h3>
            <div className='blog-list'>
                {data['recommendation'].map((item, index) => {
                    if(index>2) return;
                    return (
                        <div className="blog-item" key={index} >
                            <Link to="/blog/detail" state={item.contents}>
                                <ConList title={item.title} comm={item.comment} thumb={item.thumb} key={index} />
                            </Link>
                        </div>
                    )
                })}
            </div>

            <h3 className='blog-sub'>Tips</h3>
            <div className='blog-list'>
                {data['tips'].map((item, index) => {
                    if(index>2) return;
                    return (
                        <div className="blog-item" key={index} >
                            <Link to="/blog/detail" state={item.contents}>
                                <ConList title={item.title} comm={item.comment} thumb={item.thumb} key={index} />
                            </Link>
                        </div>
                    )
                })}
            </div>

            <h3 className='blog-sub'>Culture</h3>
            <div className='blog-list'>
                {data['culture'].map((item, index) => {
                    if(index>2) return;
                    return (
                        <div className="blog-item" key={index} >
                            <Link to="/blog/detail" state={item.contents}>
                                <ConList title={item.title} comm={item.comment} thumb={item.thumb} key={index} />
                            </Link>
                        </div>
                    )
                })}
            </div>

            <h3 className='blog-sub'>Layover travelers</h3>
            <div className='blog-list'>
                {data['layover'].map((item, index) => {
                    if(index>2) return;
                    return (
                        <div className="blog-item" key={index} >
                            <Link to="/blog/detail" state={item.contents}>
                                <ConList title={item.title} comm={item.comment} thumb={item.thumb} key={index} />
                            </Link>
                        </div>
                    )
                })}
            </div>

            <h3 className='blog-sub'>Korean Food</h3>
            <div className='blog-list'>
                {data['food'].map((item, index) => {
                    if(index>2) return;
                    return (
                        <div className="blog-item" key={index} >
                            <Link to="/blog/detail" state={item.contents}>
                                <ConList title={item.title} comm={item.comment} thumb={item.thumb} key={index} />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default All;