let lineArr = [
    {
        name: "Seoul",
        code: "A01"
    },
    {
        name: "Gongdeok",
        code: "A02"
    },
    {
        name: "Hongik Univ.",
        code: "A03"
    },
    {
        name: "Digital Media City",
        code: "A04"
    },
    {
        name: "Magongnaru",
        code: "A042"
    },
    {
        name: "Gimpo Int'l Airport",
        code: "A05"
    },
    {
        name: "Gyeyang",
        code: "A06"
    },
    {
        name: "Geomam",
        code: "A07"
    },
    {
        name: "Unseo",
        code: "A08"
    },
    {
        name: "Incheon Int'l Airport",
        code: "A10"
    }
]

let mapCode = {
    "Seoul" : {
        X: "126.96973830408173",
        Y: "37.55332081570445",
        info: {
            title: "Seoul Station",
            overview: `It is a station located at the end of the A'REX Line. It is an hour away from Incheon Airport by express train. You can travel to other regions by KTX from here.`,
            img: './img/line_station_1.jpg',
            locker: ['(B3) Near the Customer Information Center'],
            address: '서울특별시 용산구 청파로 378'
        }
    },
    "Gongdeok" : {
        X: "126.951312",
        Y: "37.5428239",
        info: {
            title: "Gongdeok Station",
            overview: `It is a convenient station to move to other places because four subway lines are connected. There is a market famous for jokbal, jeon, and sundae soup nearby.`,
            img: './img/line_station_2.jpg',
            locker: ['Path to Exits 9 and 10 on Basement Level 3'],
            address: '서울특별시 마포구 마포대로 92',
            copy: "주행거리계, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons"
        }
    },
    "Hongik Univ." : {
        X: "126.92701270968638",
        Y: "37.557289049126595",
        info: {
            title: "Hongik Univ. Station",
            overview: `Hongik University Station, commonly known as Hongdae Station, is a vibrant and popular subway station located in the heart of Seoul, South Korea. It serves as a gateway to the Hongdae area, famous for its youthful atmosphere, street art, live music, and an array of trendy shops and restaurants.`,
            img: './img/line_station_3.jpg',
            locker: ['Laon Storage - In Front of Exit 7', 'In Front of Exits 2, 3, 4, and 8'],
            address: '서울특별시 마포구 양화로 188'
        } 
    },
    "Digital Media City" : {
        X: "126.89787056593764",
        Y: "37.57749929076451",
        info: {
            title: "Digital Media City Station",
            overview: `Digital Media City Station, commonly known as DMC Station, is a subway station in Seoul, South Korea, serving the Digital Media City district. This station is a hub for media and technology industries, featuring state-of-the-art facilities and is a significant center for broadcasting and content creation.`,
            img: './img/line_station_4.jpg',
            locker: ['Inside Gates of Exits 8 and 9'],
            address: '서울특별시 마포구 성암로 184',
            copy: "LERK, CC BY-SA 4.0, via Wikimedia Commons"
        } 
    },
    "Magongnaru" : {
        X: "126.82749192031879",
        Y: "37.56562341036117",
        info: {
            title: "Magongnaru Station",
            overview: `Located near the Magok district, it provides easy access to significant business and industrial areas in Seoul, making it a popular choice for commuters and tourists.`,
            img: './img/line_station_5.jpg',
            locker: ['Next to KB Bank ATM near Exit 1', 'Near Exit 3'],
            address: '서울특별시 강서구 마곡동 373-10',
            copy: "NMetro, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
        } 
    },
    "Gimpo Int'l Airport" : {
        X: "126.80245617345462",
        Y: "37.56181351017193",
        info: {
            title: "Gimpo Int'l Airport Station",
            overview: `Gimpo Int'l Airport Station is a key transportation hub in Seoul, South Korea, providing convenient access to Gimpo International Airport. It is a critical link for travelers heading to both domestic and international destinations, serving as a major gateway for air travel.`,
            img: './img/line_station_6.jpg',
            locker: ['Entrance to Domestic Terminal', 'T luggage service - Near the Gate of Line 5'],
            address: '서울특별시 강서구 하늘길 77',
            copy: "LERK, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
        } 
    },
    "Gyeyang" : {
        X: "126.73626010126627",
        Y: "37.57176856428853",
        info: {
            title: "Gyeyang Station",
            overview: `Gyeyang Station is one of the subway stations in Incheon, South Korea, providing connections to Incheon International Airport and major city areas. This station serves as a transportation hub, connecting suburban areas to the city center.`,
            img: './img/line_station_7.jpg',
            locker: ['(F1) Next to the Self-Service Ticket Machine'],
            address: '인천광역시 계양구 다남로 24',
            copy: "메이, CC BY-SA 1.0, via Wikimedia Commons"
        } 
    },
    "Geomam" : {
        X: "126.67362747997842",
        Y: "37.569255931472725",
        info: {
            title: "Geomam Station",
            overview: `Geomam Station plays a crucial role in connecting travelers to Incheon International Airport and is instrumental in facilitating efficient transportation for both tourists and locals. Additionally, the station contributes significantly to the local economy.`,
            img: './img/line_station_8.jpg',
            locker: ['Near the elevator dedicated to KTX'],
            address: '인천광역시 서구 검바위로 26',
            copy: "Loewelad, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
        } 
    },
    "Unseo" : {
        X: "126.493652",
        Y: "37.4928013",
        info: {
            title: "Unseo Station",
            overview: `Unseo Station is situated near Incheon International Airport, and it offers a cluster of accommodation options for tourists. Additionally, there are duty-free shops in the vicinity, providing ample opportunities for leisurely shopping.`,
            img: './img/line_station_9.jpg',
            locker: ['Near Exit 1'],
            address: '인천광역시 중구 흰바위로 59번길 26',
            copy: "Twotwo2019, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
        } 
    },
    "Incheon Int'l Airport" : {
        X: "126.45251683774568",
        Y: "37.447515166909014" ,
        info: {
            title: "Incheon Int'l Airport",
            overview: `Incheon International Airport is one of South Korea's major international airports. You can reach the capital city of Seoul within 1 to 2 hours via the AREX high-speed train, express buses, and more. Click on 'Go to Airport Info' below for further details.`,
            img: './img/line_station_10.jpg',
            copy: "Ken Eckert, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
        }
    },
}

let typeCode = {
    "A05020100" : "Korean food",
    "A05020200" : "Western food",
    "A05020300" : "Japanese food",
    "A05020400" : "Chinese food",
    "A05020700" : "intercultural food",
    "A05020900" : "Cafe/Teahouse"
}

export {lineArr, mapCode, typeCode}