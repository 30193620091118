import { useState } from 'react';

import FoodList from './FoodList';

function CafeList(props) {
    let data = props.contents

    let tag1 = ['나라니', '네몽', '도레도레강화', '라온', '로즈스텔라정원', '마호가니 강화점', '수피아', '아매네 카페', '조양방직']
    let tag6 = ['강만장', '노란우체통', '드리우니', '라노비아 자동차카페', '마크로스터스까페', '목현', '밀레', '바다쏭', '브라운핸즈 개항로', '쌍곰다방', '오담']
    let tag2 = ['개떼놀이터 인천점', '갤러리 더 네이처', '고양이역 카페', '선재리커피집', '알베로테라']
    let tag4 = ['로스팅트리', '바그다드커피', '세인트커피', '손커피연구소 영종하늘도시점']
    let tag7 = ['그리다디저트', '멜란지베이커리', '미들웨이', '스이또스이또', '안스베이커리 송도점', '영종제빵소', '팟알 (Cafe POT R)', '토모루과자점', '[백년가게]세필즈과자점']
    let tag3 = ['꼬리별 베이커리 카페&풋스파', '마시안제빵소', '매화마름', '멍때림', '명장시대', '바다보다', '송도아트리움', '스타파이브 카페', '신송당', '앤드아워', '을왕리빵판다카페', '코세드']
    let tag5 = ['늘솜당', '모모아트', '숲길따라카페', '2번창고', '카페이림']
    let tag8 = ['낙조대', '마레 616', '마시랑카페', '바다앞테라스', '뻘다방', '앤드하리', '엠클리프', '카페 얼트']
    data.map((item) => {
        if(tag1.some((el) => item.title == el)) {
            item.situation = 0
        }
    })
    data.map((item) => {
        if(tag2.some((el) => item.title == el)) {
            item.situation = 1
        }
    })
    data.map((item) => {
        if(tag3.some((el) => item.title == el)) {
            item.situation = 2
        }
    })
    data.map((item) => {
        if(tag4.some((el) => item.title == el)) {
            item.situation = 3
        }
        if(item.title.includes('에스프레소')) {
            item.situation = 3
        }
    })
    data.map((item) => {
        if(tag5.some((el) => item.title == el)) {
            item.situation = 4
        }
    })
    data.map((item) => {
        if(tag6.some((el) => item.title == el)) {
            item.situation = 5
        }
    })
    data.map((item) => {
        if(tag7.some((el) => item.title == el)) {
            item.situation = 6
        }
    })
    data.map((item) => {
        if(tag8.some((el) => item.title == el)) {
            item.situation = 7
        }
    })

    const [nowData, setNowData] = useState(data)
    const [locationArr, setLocationArr] = useState([true, false, false, false, false, false, false, false, false, false, false])
    const [situationArr, setSituationArr] = useState([])

    let area = ['All', 'Ganghwa-gun', 'Gyeyang-gu', 'Namdong-gu', 'Dong-gu', 'Michuhol-gu', 'Bupyeong-gu', 'Seo-gu', 'Yeonsu-gu', 'Ongjin-gun', 'Jung-gu']

    let tag = ['insta worthy 📸', 'pet friendly 🐶', 'large cafes 🏬', 'coffee specialist ☕️', 'forest view 🌳', 'unique cafes 🎡', 'amazing deserts 🎂', 'ocean view 🏖']

    function locationChange(index) {
        if(index == 0) {
            if (!document.querySelector('#location0').checked) {
                document.querySelector('#location0').checked = true
                return
            }
            let change = locationArr
            change[0] = true
            for(let i = 1; i < change.length; i++) {
                let id = `#location${i}`
                change[i] = false;
                document.querySelector(id).checked = false;
            }
            setLocationArr(change)
        } else {
            let change = locationArr
            document.querySelector('#location0').checked = false;
            change[0] = false;
            change[index] = !change[index]

            setLocationArr(change)
        }
        changeData()
    }

    function tagChange(index) {
        let thisArr = situationArr

        let isTrue = false;
        if(thisArr.some((item) => item == index)) {
            isTrue = true;
        }

        if(!isTrue) {
            thisArr.push(index)
        } else {
            for(let i = 0; i < thisArr.length; i++) {
                if(thisArr[i] == index)  {
                    thisArr.splice(i, 1);
                    i--;
                }
            }
        }

        setSituationArr(thisArr)
        changeData()
    }

    function changeData() {
        let filterData;
        
        if(locationArr[0] && situationArr.length == 0) {
            filterData = props.contents
        } else if (!locationArr[0] && situationArr.length == 0) {
            filterData = data.filter((item) => locationArr[item.sigungucode])
        } else if (!locationArr[0] && situationArr.length != 0) {
            filterData = data.filter((item) => locationArr[item.sigungucode] && situationArr.some((el) => el == item.situation))
        } else {
            filterData = data.filter((item) => situationArr.some((el) => el == item.situation))
        }

        setNowData(filterData)
    }

    function openToggle(index) {
        let p = document.querySelectorAll('.middle-contents > p')
        let list;

        if (index == 0) {
            list = document.querySelector('.middle-list')
        } else {
            list = document.querySelector('.middle-tag')
        }

        p[index].classList.toggle('active')
        if(p[index].classList.contains('active')) {
            list.classList.add('active')
        } else {
            list.classList.remove('active')
        }
    }

    return (
        <>
        <div className='food-middle cafe'>
            <div className='contents-wrap'>
                <div className='middle-contents'>
                    <p onClick={() => openToggle(0)}>Location</p>
                    <div className='middle-list'>
                        {area.map((item, index) => {
                            let id = `location${index}`
                            return (
                                <div className='checkbox-wrap' key={index}>
                                    <input type="checkbox" id={id} defaultChecked={locationArr[index]} onClick={() => locationChange(index)} />
                                    <label htmlFor={id}>{item}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='middle-contents'>
                    <p onClick={() => openToggle(1)}>Situations</p>
                    <div className='middle-tag'>
                        {tag.map((item, index) => {
                            return (
                                <button className={situationArr.includes(index) ? "active" : ''} onClick={() => tagChange(index)} key={index} >{item}</button>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
        <FoodList content={nowData} />
        </>
    )
}

export default CafeList