import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import FoodList from './FoodList';

function ResList(props) {
    let data = props.contents

    let situ1 = ['소성정', '삼송가든 인천본점', '녹우돈', '태백산']
    let situ2 = ['남동공단떡볶이', '스시이와', '금문도', '기와집담', '임파스토', '이당비스트로', 'CK뉴옥바닷가재', '모꼬지']
    let situ3 = ['메콩사롱', '신신분식', '전라도대왕조개구이', '오구당당 부평본점', '온기족발', '해운정양식장', '십리향', '툴롱(toulon)', '정원식탁']
    data.map((item) => {
        if(item.title.includes('한우')) {
            item.situation = 0
        } else if (situ1.some((el) => item.title == el)) {
            item.situation = 0
        }
    })
    data.map((item) => {
        if (situ2.some((el) => item.title == el)) {
            item.situation = 1
        }
    })
    data.map((item) => {
        if(item.title.includes('백년가게')) {
            item.situation = 2
        }
    })
    data.map((item) => {
        if (situ3.some((el) => item.title == el)) {
            item.situation = 3
        }
    })
    data.map((item) => {
        if(item.title.includes('해물') || item.title.includes('횟') || item.title.includes('수산')) {
            item.situation = 4
        }
    })

    const [nowData, setNowData] = useState(data)
    const [locationArr, setLocationArr] = useState([true, false, false, false, false, false, false, false, false, false, false])
    const [typeArr, setTypeArr] = useState([])
    const [situationArr, setSituationArr] = useState([])

    let area = ['All', 'Ganghwa-gun', 'Gyeyang-gu', 'Namdong-gu', 'Dong-gu', 'Michuhol-gu', 'Bupyeong-gu', 'Seo-gu', 'Yeonsu-gu', 'Ongjin-gun', 'Jung-gu']

    let type = ['Korean food 🇰🇷', 'Western food 🍝', 'Chinese food 🥢', 'Intercultural food 🌏', 'Japanese food 🍣']
    let typeCode = ['A05020100', 'A05020200', 'A05020400', 'A05020700', 'A05020300']

    let tag = ['Hanwoo (K-beef) 🥩', 'recommend reserving 📞️', '100 years of history 🕰', 'insta worthy 📸', 'Seafood special 🎣']

    function openToggle(index) {
        let p = document.querySelectorAll('.middle-contents > p')
        let list;

        if (index == 0) {
            list = document.querySelector('.middle-list')
        } else if(index == 2) {
            list = document.querySelector('.middle-tag')
        } else {
            list = document.querySelector('.middle-type')
        }

        p[index].classList.toggle('active')
        if(p[index].classList.contains('active')) {
            list.classList.add('active')
        } else {
            list.classList.remove('active')
        }
    }

    function locationChange(index) {
        if(index == 0) {
            if (!document.querySelector('#location0').checked) {
                document.querySelector('#location0').checked = true
                return
            }
            let change = locationArr
            change[0] = true
            for(let i = 1; i < change.length; i++) {
                let id = `#location${i}`
                change[i] = false;
                document.querySelector(id).checked = false;
            }
            setLocationArr(change)
        } else {
            let change = locationArr
            document.querySelector('#location0').checked = false;
            change[0] = false;
            change[index] = !change[index]

            setLocationArr(change)
        }
        changeData()
    }

    function typeChange(index) {
        let thisArr = typeArr

        let isTrue = false;
        if(thisArr.some((item) => item == typeCode[index])) {
            isTrue = true;
        }

        if(!isTrue) {
            thisArr.push(typeCode[index])
        } else {
            for(let i = 0; i < thisArr.length; i++) {
                if(thisArr[i] == typeCode[index])  {
                    thisArr.splice(i, 1);
                    i--;
                }
            }
        }

        setTypeArr(thisArr)
        changeData()

        let btnArr = document.querySelectorAll('.middle-type button')
        btnArr[index].classList.toggle('active')
    }

    function tagChange(index) {
        let thisArr = situationArr

        let isTrue = false;
        if(thisArr.some((item) => item == index)) {
            isTrue = true;
        }

        if(!isTrue) {
            thisArr.push(index)
        } else {
            for(let i = 0; i < thisArr.length; i++) {
                if(thisArr[i] == index)  {
                    thisArr.splice(i, 1);
                    i--;
                }
            }
        }

        setSituationArr(thisArr)
        changeData()

        let btnArr = document.querySelectorAll('.middle-tag button')
        btnArr[index].classList.toggle('active')
    }

    function changeData() {
        let filterData;
        
        if(locationArr[0] && situationArr.length == 0 && typeArr == 0) {
            filterData = props.contents
        } else if (locationArr[0] && typeArr.length != 0 && situationArr.length == 0) {
            filterData = data.filter((item) => typeArr.some((el) => el == item.cat3))
        } else if (locationArr[0] && typeArr.length != 0 && situationArr.length != 0) {
            filterData = data.filter((item) => typeArr.some((el) => el == item.cat3) && situationArr.some((el) => el == item.situation))
        } else if (locationArr[0] && typeArr.length == 0 && situationArr.length != 0) {
            filterData = data.filter((item) => situationArr.some((el) => el == item.situation))
        } else if (!locationArr[0] && typeArr.length == 0 && situationArr.length == 0) {
            filterData = data.filter((item) => locationArr[item.sigungucode])
        } else if (!locationArr[0] && typeArr.length != 0 && situationArr.length == 0) {
            filterData = data.filter((item) => locationArr[item.sigungucode] && typeArr.some((el) => el == item.cat3))
        } else if (!locationArr[0] && typeArr.length == 0 && situationArr.length != 0) {
            filterData = data.filter((item) => locationArr[item.sigungucode] && situationArr.some((el) => el == item.situation))
        } else {
            filterData = data.filter((item) => locationArr[item.sigungucode] && situationArr.some((el) => el == item.situation)&& typeArr.some((el) => el == item.cat3))
        }

        setNowData(filterData)
    }

    return (
        <>
        <div className='food-middle res'>
            <div className='contents-wrap'>
                <div className='middle-contents'>
                    <p onClick={() => openToggle(0)}>Location</p>
                    <div className='middle-list'>
                        {area.map((item, index) => {
                            let id = `location${index}`
                            return (
                                <div className='checkbox-wrap' key={index}>
                                    <input type="checkbox" id={id} defaultChecked={locationArr[index]} onClick={() => locationChange(index)} />
                                    <label htmlFor={id}>{item}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='middle-contents'>
                    <p onClick={() => openToggle(1)}>Food Type</p>
                    <div className='middle-type'>
                        {type.map((item, index) => {
                            return (
                                <button className={typeArr.includes(index) ? "active" : ''} onClick={() => typeChange(index)} key={index} >{item}</button>
                            )
                        })}
                    </div>
                </div>
                <div className='middle-contents'>
                    <p onClick={() => openToggle(2)}>Situations</p>
                    <div className='middle-tag'>
                        {tag.map((item, index) => {
                            return (
                                <button className={situationArr.includes(typeCode[index]) ? "active" : ''} onClick={() => tagChange(index)} key={index} >{item}</button>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
        <div className='food-link'>
            <Link to="/blog/detail" state={"./blog/food/food5.html"}>
            </Link>
        </div>
        <FoodList content={nowData} />
        </>
    )
}

export default ResList