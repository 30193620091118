import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from "./layout/Header";
import Footer from "./layout/Footer";

import Main from "./pages/Main";

import Airport from "./pages/Airport";
import Linetour from "./pages/Linetour";
import Themedtour from "./pages/Themedtour";
import Cafe from "./pages/Cafe";
import Blog from "./pages/Blog";

import Detail from "./pages/blog/Detail";
import About from "./pages/About";

import FoodDetail from './pages/food/FoodDetail';

import List from './pages/theme/List';
import ThemeDetail from './pages/theme/ThemeDetail';

import AirportList from './pages/airport/AirportList';
import AirportDetail from './pages/airport/AirportDetail';

function App() {

  useEffect(() => {
    function getLanguage() {
      return navigator.language || navigator.userLanguage;
    }

    let now = getLanguage()
    let languages = ['zh-TW','zh-CN','ja','vi','th','tl','km','my','mn','ru','en','fr','ar']
    if(languages.some((item) => now.includes(item))) {
      now = languages.filter((item) => now.includes(item))
      now = now[0]
    } else {
      now = 'en'
    }

    let page = `/ko/${now}`

    setCookie('googtrans', page,1);
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  
  function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
  }

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'ko',
        includedLanguages: 'ko,zh-CN,zh-TW,ja,vi,th,tl,km,my,mn,ru,en,fr,ar', // include this for selected languages
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };

  return (
    <>
      <div id="google_translate_element" className='hd_lang'></div>
      <Header />
      <Routes>
        <Route path="/" element={ <Main /> } />
        <Route path="/airport" element={ <Airport /> } />
        <Route path="/arex" element={ <Linetour /> } />
        <Route path="/themes" element={ <Themedtour /> } />
        <Route path="/food" element={ <Cafe /> } />
        <Route path="/blog" element={ <Blog /> } />
        
        <Route path="/blog/detail" element={ <Detail /> } />
        <Route path="/about" element={ <About /> } />

        <Route path="/food/detail" element={ <FoodDetail /> } />

        <Route path="/themes/list" element={ <List /> } />
        <Route path="/themes/list/detail" element={ <ThemeDetail /> } />
        
        <Route path="/airport/list" element={ <AirportList /> } />
        <Route path="/airport/detail" element={ <AirportDetail /> } />
        
      </Routes>
      <Footer />
    </>
  );
}

export default App;
