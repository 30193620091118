function About() {
    return (
        <>
            <div className="about-top">
                <div className="contents-wrap">
                    <img src="./img/about_logo.png" alt="nearport logo" />
                    <p>NEAR + AIRPORT
                        <span>: Find things to do Near the Airport</span>
                    </p>
                    <div>
                    Near, port is a website made for people who are to travel South Korea from all around the world.<br/>
                    We share our vision as a pathfinder for those who are staying short term in Korea, or about to leave.<br/>
                    We provide important information from the <b>airport</b>, through <b>Incheon</b>, and to <b>Seoul</b>. 
                    </div>
                </div>
            </div>
            <div className="about-contents contents-wrap">
                <h3 className="about-title">YOU CAN FIND</h3>
                <ul className="about-list">
                    <li className="airport">
                        <p className="name">
                            AIRPORT <br />
                            INFO
                        </p>
                        <div className="explain">
                            You can find out all the information about the airport, especially for travelers staying in the airport for a long time, and airport shoppers.
                        </div>
                    </li>
                    <li className="line">
                        <p className="name">
                            A’REX<br />
                            LINE TOUR
                        </p>
                        <div className="explain">
                            People willing to know what to do near the airport that has an easy access by the express subway A’REX can check out.
                        </div>
                    </li>
                    <li className="theme">
                        <p className="name">
                            THEMED<br />
                            TOUR
                        </p>
                        <div className="explain">
                            No need to plan! There are many themes we provide in near,port that will fulfill your needs of an occasional or daily trip, all in the city of Incheon.
                        </div>
                    </li>
                    <li className="food">
                        <p className="name">
                            CAFÉ &<br />
                            RESTAURANTS
                        </p>
                        <div className="explain">
                            South Korea is known for good restaurants and cafes. All your needs will be in this page, also good for searching vegan/halal food.
                        </div>
                    </li>
                    <li className="blog">
                        <p className="name">
                            BLOG
                        </p>
                        <div className="explain">
                            All the tips we couldn’t put in the website, we provide it here. From the culture, history, to tips on ordering food and programs, we write them.
                        </div>
                    </li>
                </ul>
            </div>
            <div className="about-contents contents-wrap">
                <h3 className="about-title">recommended menus for...</h3>
                <ul className="about-recommend">
                    <li>
                        <div className="left">
                            <p className="title">
                                Transfer & Short-term<br />
                                Travlers
                            </p>
                            <div className="text">
                            Near Korea, there is China, Japan, and Russia. Feel free to visit for a few days, and feel the K-spirit!
                            </div>
                        </div>
                        <div className="right">
                            <ul>
                                <li>
                                    <p>· AIRPORT INFO</p>
                                    <span>From emergency calls  to restaurants and duty free shops, we got all the information you need for the airport.</span>
                                </li>
                                <li>
                                    <p>· A’REX LINE TOUR</p>
                                    <span>Take a ride from Incheon Airport to Seoul! We provide all the things you can do near the subway stations.</span>
                                </li>
                                <li>
                                    <p>· BLOG</p>
                                    <span>Halal/vegan restaurants, safety tips, and basic phrases you can use in Korea. We provide information foreign travelers want the most in our articles.</span>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="left">
                            <p className="title">
                                Anyone preparing<br />
                                to come to Korea 
                            </p>
                            <div className="text">
                            You chose to visit Korea, but don’t know what exactly to do? <br />
                            These menus will help you pick the best choice.
                            </div>
                        </div>
                        <div className="right">
                            <ul>
                            <li>
                                    <p>· A’REX LINE TOUR</p>
                                    <span>Take a ride from Incheon Airport to Seoul! We provide all the things you can do near the subway stations.</span>
                                </li>
                                <li>
                                    <p>· THEMED TOUR</p>
                                    <span>These themes are chosen to ease your trip, but also make it high quality. Our four themes will show you all around Incheon.</span>
                                </li>
                                <li>
                                    <p>· CAFÉ & RESTAURANTS</p>
                                    <span>We have locations and situation buttons you can use in order to pick the best cafes and restaurants we have in Incheon.</span>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="left">
                            <p className="title">
                                Travelers leaving <br />
                                Korea soon
                            </p>
                            <div className="text">
                            If you’re done everything in Korea that you planned, but still have some time left, we have your back.
                            </div>
                        </div>
                        <div className="right">
                            <ul>
                                <li>
                                    <p>· AIRPORT INFO</p>
                                    <span>From emergency calls  to restaurants and duty free shops, we got all the information you need for the airport.</span>
                                </li>
                                <li>
                                    <p>· THEMED TOUR</p>
                                    <span>These themes are chosen to ease your trip, but also make it high quality. Our four themes will show you all around Incheon.</span>
                                </li>
                                <li>
                                    <p>· BLOG</p>
                                    <span>Halal/vegan restaurants, safety tips, and basic phrases you can use in Korea. We provide information foreign travelers want the most in our articles.</span>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </>    
    )
}

export default About