import { Link } from 'react-router-dom';

import Slider from './main/Slider'
import Station from './main/Station'
import SwiperContents from './main/SwiperContents'

import AOS from 'aos'
import 'aos/dist/aos.css';

function Main() {

    AOS.init({
        duration: 1000,
    })
    
    return (
        <>
            <Slider />

            <Station />

            <div className="contents-wrap main-contents airport" data-aos="fade-up">
                <div className="airport-illust"></div>
                <div className="airport-text main-info">
                    <h4>Airport Information</h4>
                    <p>
                        <span className='no-mobile'>
                        Incheon International Airport is known as one of the best international airports in the world. However, it’s also VERY big, and can be confusing to find whatever you are looking for. <br/>
                        </span>
                        Near, Port provides you all the information from places to relax, go shopping, eat, and more that you can imagine.
                    </p>
                    <div className="btn-area right">
                        <Link to="/airport">
                            <button className="btn btn-green">
                                Learn more
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <SwiperContents />

            <div className="contents-wrap main-contents transfer" data-aos="fade-up">
                <div className="transfer-text main-info">
                    <h4>What should we do as a transfer traveler?</h4>
                    <p>
                    <span className='no-mobile'>Many tourists visit Korea for short term, yet to go to another country nearby. If you come here unprepared, you might have to cancel all your trips. <br/></span>
                        In this section, you can find out what kind of visa you need, how long you can stay, and what kind of programs they provide in South Korea. 
                    </p>
                    <div className="btn-area left">
                        <Link to="/blog/detail" state={"./blog/tip/tip5.html"}>
                            <button className="btn btn-green-border">
                                Learn more
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="transfer-illust"></div>
            </div>

            <div className="contents-wrap main-contents inner" data-aos="fade-up">
                <h3 className="main-title">Recommended articles</h3>
                <div className="article">
                    <div className="article-l item">
                        <Link to="/blog/detail" state={"./blog/tip/tip3.html"}>
                            <img src="./blog/img/tip_3.jpg" alt="" />
                            <p className="article-title">
                                Navigating Korea: A Foreigner's Guide to Public Transportation
                            </p>
                        </Link>
                    </div>
                    <div className="article-r">
                        <div className="item">
                            <Link to="/blog/detail" state={"./blog/culture/culture5.html"}>
                                <div className="item-l">
                                <img src="./blog/img/cul_5.jpg" alt="" />
                                </div>
                                <div className="item-r">
                                    <p className="item-title">
                                        Navigating Etiquette: Unraveling Basic Manners in Korea
                                    </p>
                                    <p className="item-contents">
                                        South Korea, a land steeped in tradition and modernity, has a rich tapestry of social customs that might intrigue and sometimes perplex tourists. Understanding these basic manners is not just a way to blend in; it's a respectful nod to Korean culture. In this article, let's decode the subtle nuances of politeness that can make your Korean experience smoother and more enjoyable.
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="/blog/detail" state={"./blog/food/food4.html"}>
                                <div className="item-l">
                                    <img src="./blog/img/food_4.jpg" alt="" />
                                </div>
                                <div className="item-r">
                                    <p className="item-title">
                                    Culinary Marvels: Uncovering Extraordinary Korean Delicacies Unique to Incheon
                                    </p>
                                    <p className="item-contents">
                                    Incheon, a city steeped in culture and culinary innovation, offers a treasure trove of extraordinary Korean dishes that are not just delicious but also incredibly unique. These culinary marvels are a testament to Korea's rich gastronomic heritage and inventive spirit. In this guide, let's embark on a gastronomic adventure in Incheon, exploring the extraordinary Korean food items that are exclusive to this vibrant city, promising an unforgettable culinary experience.
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main;