import { Link } from 'react-router-dom';

function FoodItem(props) {
    let item = props.item
    let tagC = ['insta worthy 📸', 'pet friendly 🐶', 'large cafes 🏬', 'coffee specialist ☕️', 'forest view 🌳', 'unique cafes 🎡', 'amazing deserts 🎂', 'ocean view 🏖']
    let tagR = ['Hanwoo (K-beef) 🥩', 'recommend reserving 📞️', '100 years of history 🕰', 'insta worthy 📸', 'Seafood special 🎣']

    let tag = item.cat3 == "A05020900" ? tagC[item.situation] : tagR[item.situation]
    return (
        <div className="food-item">
                        <Link to="/food/detail" state={item.contentid}>
                            <div className="top">
                                <img src={item.firstimage ? item.firstimage : "./img/noimage.png"} alt={item.title} />
                            </div>
                            <div className="bottom">
                                <div className="title"><span>{item.title}</span><span className="title-ko notranslate">{item.title}</span></div>
                                <div className="tag-area">
                                    {item.situation != undefined ?  
                                    <span className="food-tag">
                                        {tag}
                                    </span> 
                                    : ""}
                                </div>
                            </div>
                        </Link>
                    </div>
    )
}

export default FoodItem