let data = {
    main: {
        title: 'Your Comprehensive Guide to Planning a Trip to Korea: Documents, Vaccinations, and Essential Tips',
        comment: `Planning a trip to South Korea is an exciting venture into a world of rich culture, delectable cuisine, and breathtaking landscapes. To ensure a smooth and hassle-free journey, it's crucial to be well-prepared with the right documents, vaccinations, and essential knowledge. In this comprehensive guide, we'll walk you through the necessary paperwork, vaccinations, and vital tips to make your Korean adventure unforgettable.`,
        thumb: './blog/img/tip_5.jpg',
        contents: './blog/tip/tip5.html',
        writer: 'team near, port'
    },
    recommendation : [
        {
            title: 'Embracing Tradition: The Joy of Wearing Hanbok in South Korea',
            comment: `South Korea, a land where ancient traditions blend seamlessly with modern marvels, is a traveler's paradise. Beyond its bustling cities and scenic landscapes, there are unique experiences waiting to be discovered. One such delightful adventure is donning the elegant and vibrant Hanbok, the traditional Korean attire that has stood the test of time. In this article, we will explore the enchanting world of Hanbok and why it's a must-try experience during your visit to Korea.`,
            thumb: './blog/img/rec_1.jpg',
            contents: './blog/recommend/recommend1.html'
        },
        {
            title: 'Souvenirs in Korea: Bringing Home a Piece of Korean Culture',
            comment: `Hey there, fellow travelers! So, you've just had the adventure of a lifetime exploring the wonders of South Korea. The vibrant markets, the ancient palaces, the bustling streets - each moment was a treasure trove of experiences. But no trip is complete without the perfect souvenir to bring the memories home. In the heart of Korea, choosing the right keepsake is an art in itself. Let me guide you through the maze of options and introduce you to the must-buy souvenirs that will capture the essence of your Korean escapade.`,
            thumb: './blog/img/rec_2.jpg',
            contents: './blog/recommend/recommend2.html'
        },
        {
            title: 'Unveiling K-Beauty Marvels: Your Introduction to Korean Cosmetic Brands and Products',
            comment: `Prepare to be captivated by the allure of K-Beauty, where ancient traditions meet cutting-edge skincare innovations. South Korea has given the world a treasure trove of cosmetic brands and products, each promising to transform your skin into a radiant masterpiece. If you're ready to embark on a beauty journey like no other, let’s dive into the enchanting universe of Korean cosmetic brands and discover the magic they have to offer.`,
            thumb: './blog/img/rec_3.jpg',
            contents: './blog/recommend/recommend3.html'
        },
        {
            title: `Exploring Korea Solo: A Solo Traveler's Guide to Unforgettable Experiences`,
            comment: 'Traveling solo in South Korea can be an incredibly rewarding experience. The country is not only safe but also offers a wide range of activities and destinations that are perfect for solo travelers. Here are some recommendations for things to do and places to visit when traveling alone in Korea:',
            thumb: './blog/img/rec_4.jpg',
            contents: './blog/recommend/recommend4.html'
        }
    ],
    tips : [
        {
            title: 'Navigating the Culinary Landscape: A Guide to Reserving Restaurants in Korea',
            comment: `Embarking on a culinary adventure in South Korea is an experience to savor. From sizzling barbecue joints to cozy, traditional eateries, the options are as diverse as the flavors. Securing your spot in these gastronomic havens is essential, and fortunately, Korea offers a variety of reservation methods. In this guide, we'll walk you through the art of reserving a restaurant in Korea, whether it's through a phone call, a text message, online platforms, or handy apps. Get ready to explore the world of Korean cuisine stress-free.`,
            thumb: './blog/img/tip_1.jpg',
            contents: './blog/tip/tip1.html'
        },
        {
            title: `Embracing the Seasons: A Year-Round Journey Through Korea's Splendors`,
            comment: `Korea, a land of captivating contrasts, weaves its beauty seamlessly through the changing seasons. Each time of the year bestows its unique charm upon the landscape, offering travelers an ever-evolving tapestry of experiences. In this article, we invite you to embark on a delightful journey, exploring the wonders that Korea unfolds across its four distinct seasons.`,
            thumb: './blog/img/tip_2.jpg',
            contents: './blog/tip/tip2.html'
        },
        {
            title: `Navigating Korea: A Foreigner's Guide to Public Transportation`,
            comment: `Stepping into the bustling metropolis of South Korea is an exhilarating experience, and one of the most convenient ways to explore its vibrant cities is through its robust public transportation system. In this article, we’ll unravel the secrets of Korea’s buses, subways, and taxis, ensuring that every foreign traveler can confidently navigate the maze of public transportation, making their Korean adventure both seamless and unforgettable.`,
            thumb: './blog/img/tip_3.jpg',
            contents: './blog/tip/tip3.html'
        },
        {
            title: 'Unlocking Korea: Essential Korean Phrases for Your Travel Adventure',
            comment: `Embarking on a journey to South Korea is a thrilling experience, and while many Koreans speak English, knowing a few basic Korean phrases can greatly enhance your travel experience. In this article, we'll provide you with a handy guide to essential Korean phrases that will help you navigate Korea's vibrant cities, engage with locals, and immerse yourself in the rich culture of this beautiful country.`,
            thumb: './blog/img/tip_4.jpg',
            contents: './blog/tip/tip4.html'
        },
        {
            title: 'Your Comprehensive Guide to Planning a Trip to Korea: Documents, Vaccinations, and Essential Tips',
            comment: `Planning a trip to South Korea is an exciting venture into a world of rich culture, delectable cuisine, and breathtaking landscapes. To ensure a smooth and hassle-free journey, it's crucial to be well-prepared with the right documents, vaccinations, and essential knowledge. In this comprehensive guide, we'll walk you through the necessary paperwork, vaccinations, and vital tips to make your Korean adventure unforgettable.`,
            thumb: './blog/img/tip_5.jpg',
            contents: './blog/tip/tip5.html'
        }
    ],
    culture : [
        {
            title:`Sip with Respect: Navigating Drinking Etiquette in Korea`,
            comment: `Korea’s drinking culture is as diverse and nuanced as the country itself, and being aware of the drinking etiquettes can make your experience immensely enjoyable. In this article, we’ll delve into the intricate world of Korean drinking traditions, offering insights into what you should know before you raise your glass in this vibrant land.`,
            thumb: './blog/img/cul_1.jpg',
            contents: './blog/culture/culture1.html'
        },
        {
            title:`Dining with Grace: Unraveling the Exquisite Manners in Korean Restaurants`,
            comment: `Stepping into a Korean restaurant is not just about indulging in exquisite cuisine; it’s an immersion into a world where tradition meets culinary artistry. Understanding the etiquette and eating culture in Korea is crucial to fully appreciate the gastronomic journey. In this article, we'll uncover the intricate manners that shape the dining experience in Korean restaurants, inviting you to savor not just the food but the rich cultural nuances that come with it.`,
            thumb: './blog/img/cul_2.jpg',
            contents: './blog/culture/culture2.html'
        },
        {
            title:`Embracing Diversity: Exploring Unique Cultures in Korea`,
            comment: `South Korea, a land of captivating contrasts, is a treasure trove of cultural nuances that may intrigue and sometimes bewilder tourists. From ancient traditions to modern subcultures, the country offers a rich tapestry of experiences. In this article, we’ll unravel some specific cultures in Korea that might pique the interest or curiosity of travelers, inviting you to delve deeper into the heart of this fascinating nation.`,
            thumb: './blog/img/cul_3.jpg',
            contents: './blog/culture/culture3.html'
        },
        {
            title:`The Beat of Fandom: Navigating K-Pop Fan Cultures in Korea`,
            comment: `Stepping into the bustling streets of Seoul, you’re not just in a city; you’re in the heart of the global K-Pop phenomenon. South Korea's music industry, particularly K-Pop, has captured the hearts of millions worldwide. For tourists, understanding the vibrant world of K-Pop fan cultures can be both intriguing and, at times, bewildering. In this article, let's unravel the energetic realm of K-Pop fanatics in Korea, shedding light on the passions that fuel this extraordinary cultural phenomenon.`,
            thumb: './blog/img/cul_4.jpg',
            contents: './blog/culture/culture4.html'
        },
        {
            title:`Navigating Etiquette: Unraveling Basic Manners in Korea`,
            comment: `South Korea, a land steeped in tradition and modernity, has a rich tapestry of social customs that might intrigue and sometimes perplex tourists. Understanding these basic manners is not just a way to blend in; it’s a respectful nod to Korean culture. In this article, let's decode the subtle nuances of politeness that can make your Korean experience smoother and more enjoyable.`,
            thumb: './blog/img/cul_5.jpg',
            contents: './blog/culture/culture5.html'
        }
    ],
    layover : [
        {
            title:`Seoul to Busan: Your Effortless Journey Across South Korea`,
            comment: `Embarking on a trip from Incheon to Busan, two of South Korea's bustling cities, might seem daunting to a foreign tourist. However, fear not! This article provides you with a straightforward guide, breaking down the journey into easy steps. By the end, you’ll be well-prepared to enjoy the diverse beauty and culture of both cities.`,
            thumb: './blog/img/lay_1.jpg',
            contents: './blog/layover/layover1.html'
        },
        {
            title:`Incheon Airport Unveiled: A World of Wonders Within`,
            comment: `Incheon International Airport, often regarded as one of the world's best airports, isn't just a transit hub; it's a destination in itself. Offering a myriad of amenities and experiences, Incheon Airport ensures your time spent inside is as delightful as your adventures outside. Let’s uncover the hidden gems waiting for you within the airport's walls.`,
            thumb: './blog/img/lay_2.jpg',
            contents: './blog/layover/layover2.html'
        },
        {
            title:`A Day in Seoul: Making the Most of Your Layover`,
            comment: `Seoul, the vibrant heart of South Korea, is a city of contrasts, seamlessly blending ancient traditions with cutting-edge modernity. Even if you have just a day during your layover, Seoul welcomes you with open arms and a plethora of experiences. In this guide, discover how to make the most of your brief visit, immersing yourself in the essence of this captivating metropolis.`,
            thumb: './blog/img/lay_3.jpg',
            contents: './blog/layover/layover3.html'
        },
        {
            title:`Paradise City, Incheon: Your Gateway to Unforgettable Layover Adventures`,
            comment: `Nestled near Incheon International Airport, Paradise City is a luxurious haven that beckons travelers with enticing possibilities. For those with a layover, this entertainment complex is not just a retreat but an opportunity to experience the best of Korean hospitality and entertainment. In this guide, we unveil the wonders awaiting you within Paradise City's vibrant precincts, ensuring your layover is transformed into a memorable adventure.`,
            thumb: './blog/img/lay_4.jpg',
            contents: './blog/layover/layover4.html'
        }
    ],
    food : [
        {
            title:`Incheon's Super Cute and Trendy Instagrammable Cafés: Where Every Sip Is a Snapshot`,
            comment: `Incheon, South Korea’s vibrant port city, is not only a hub of cultural richness but also a treasure trove of adorable and trendy cafés that are every Instagrammer’s dream. From whimsical décor to creative culinary delights, these cafés don’t just serve coffee; they serve up the perfect backdrop for your next Instagram post. Join us on a virtual tour of Incheon’s most delightful and Instagrammable cafés, where each sip is a moment worth capturing.`,
            thumb: './blog/img/food_1.jpg',
            contents: './blog/food/food1.html'
        },
        {
            title:`Halal Heaven: Exploring Incheon's Best Restaurants for Authentic Halal Cuisine`,
            comment: `Incheon, a vibrant city in South Korea, is not only a melting pot of cultures but also a paradise for food enthusiasts. For Muslim travelers, finding authentic Halal cuisine is essential for a fulfilling dining experience. In this guide, we’ll navigate through Incheon’s culinary landscape, unveiling the city's top restaurants offering Halal food and menus, ensuring your dining journey is both delicious and culturally enriching.`,
            thumb: './blog/img/food_2.jpg',
            contents: './blog/food/food2.html'
        },
        {
            title:`Veggie Bliss: Exploring Incheon's Best Restaurants for Delectable Vegan Cuisine`,
            comment: `Incheon, a bustling city in South Korea, has become a haven for vegans seeking diverse and delicious plant-based meals. With a culinary scene that embraces healthy living, Incheon offers an array of restaurants catering to vegan diets. In this guide, we'll navigate through Incheon's gastronomic landscape, unveiling the city's top restaurants offering delectable vegan food and menus, ensuring your dining experience is not only cruelty-free but also incredibly flavorful.`,
            thumb: './blog/img/food_3.jpg',
            contents: './blog/food/food3.html'
        },
        {
            title:`Culinary Marvels: Uncovering Extraordinary Korean Delicacies Unique to Incheon`,
            comment: `Incheon, a city steeped in culture and culinary innovation, offers a treasure trove of extraordinary Korean dishes that are not just delicious but also incredibly unique. These culinary marvels are a testament to Korea's rich gastronomic heritage and inventive spirit. In this guide, let’s embark on a gastronomic adventure in Incheon, exploring the extraordinary Korean food items that are exclusive to this vibrant city, promising an unforgettable culinary experience.`,
            thumb: './blog/img/food_4.jpg',
            contents: './blog/food/food4.html'
        },
        {
            title:`Culinary Marvels: Uncovering Extraordinary Korean Delicacies Unique to Incheon`,
            comment: `Incheon, a city steeped in culture and culinary innovation, offers a treasure trove of extraordinary Korean dishes that are not just delicious but also incredibly unique. These culinary marvels are a testament to Korea's rich gastronomic heritage and inventive spirit. In this guide, let’s embark on a gastronomic adventure in Incheon, exploring the extraordinary Korean food items that are exclusive to this vibrant city, promising an unforgettable culinary experience.`,
            thumb: './blog/img/food_5_1.jpg',
            contents: './blog/food/food5.html'
        }
    ]
}

export default data;