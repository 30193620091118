import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Slider() {
    const [slideImg, setSlideImg] = useState(0);
    const [down, setDown] = useState(false)

    let imgArr = [
        {
            img: "main_slide_1.jpg",
            alt: ""
        },
        {
            img: "main_slide_2.jpg",
            alt: ""
        },
        {
            img: "main_slide_3.jpg",
            alt: ""
        },
    ]

    useEffect(() => {
        const timeout = setInterval(() => {
            setDown(true)

            setTimeout(() => {
                let change = slideImg === 2 ? 0 : slideImg + 1
                setSlideImg(change)
            },500)

            function removeDown() {
                setDown(false)
            }

            setTimeout(removeDown, 600)
        }, 7000);
   
   return () => clearInterval(timeout)
   }, [slideImg])

    let src = `./img/${imgArr[slideImg].img}`
    let alt = imgArr[slideImg].alt

    return (
        <>
            <div className="main-slider-wrap">
                <Link to="/about">
                    <div className="contents-wrap main-slider">
                        <div className="main-slider-l">
                            <h1>
                                Looking for<br />
                                things to do<br />
                                near the airport?
                            </h1>
                            <p>
                            Near, port is an informative platform providing things to enjoy from the airport, all along to Seoul, and back. <br />
                            For more information, go ahead and click the button below!
                            </p>
                            <button className="btn btn-green btn-l">About Us</button>
                        </div>
                        <div className="main-slider-r">
                            <div className={down ? "handle down" : "handle"}></div>
                            <div className='window-wrap'>
                                <div className={down ? "window down" : "window"}></div>
                                <img src={src} alt={alt} />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default Slider