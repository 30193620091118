function ConList({ title, comm, thumb, writer }) {

    let isWrite = writer ? <span>by {writer}</span> : ''
    return (
        <>
            <div className="top">
                <img src={thumb} alt={title} />
            </div>
            <div className="bottom">
                <h5 className="blog-title">
                    {title}
                </h5>
                {isWrite}
                <p>
                    {comm}
                </p>
            </div>
        </>
    )
}

export default ConList;